import React, { Component, Fragment } from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import alerts from '../../utils/helperFunctions/alerts';
import { changePasswordAction } from '../../actions/admin';
import common from '../../utils/helperFunctions/common';
import PropTypes from 'prop-types';

class ChangePassword extends Component {
  state = {
    loading: false,
    hasError: false,
    errorMessage: '',
  };

  closeAlert = () => this.setState({ hasError: false });

  toggleChangePwdDialog = () => {
    this.setState({ loading: false, hasError: false });
    this.props.closeDialog();
  };

  changePassword = async (values) => {
    this.setState({ hasError: false, errordisplay: '', loading: true });
    try {
      const { data } = await changePasswordAction(values);
      if (data && data.success) {
        alerts.success('Password changed Successfully');
        this.toggleChangePwdDialog();
      } else {
        this.setState({
          hasError: true,
          errordisplay: data.message || 'Error while changing password',
          loading: false,
        });
      }
    } catch (error) {
      let message =
        common.getResponseFromError(error) ||
        'An error occurred while changing password. Try again.';

      this.setState({
        hasError: true,
        errordisplay: message,
        loading: false,
      });
    }
  };

  static propTypes = {
    closeDialog: PropTypes.func.isRequired,
  };

  render() {
    const { loading, hasError, errordisplay } = this.state;

    return (
      <Fragment>
        <ChangePasswordForm
          onSubmit={this.changePassword}
          onClose={this.toggleChangePwdDialog}
          loading={loading}
          hasError={hasError}
          errordisplay={errordisplay}
          closeAlert={this.closeAlert}
        />
      </Fragment>
    );
  }
}

export default ChangePassword;
