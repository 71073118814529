import React, { Component } from 'react';
import Dialog from '../common/Dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { InputAdornment } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { TextFieldComponent, AlertComponent } from '../common/formComponents';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import system from '../../utils/system/system';

class ChangePasswordForm extends Component {
  state = {
    showPassword: false,
  };

  onFromSubmit = (values) => {
    const { onSubmit } = this.props;
    onSubmit(values);
  };

  handlePwdVisibility = (event) => {
    event.preventDefault();
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  getChangePasswordForm = () => {
    const { handleSubmit, errordisplay, hasError, closeAlert } = this.props;
    const { showPassword } = this.state;

    return (
      <form onSubmit={handleSubmit(this.onFromSubmit)} id="changePassword">
        {hasError && (
          <AlertComponent open={hasError} onClose={closeAlert}>
            {errordisplay}
          </AlertComponent>
        )}

        <TextFieldComponent
          label="Current Password"
          name="currPassword"
          placeholder="Enter current password"
          type="password"
          className="width-90"
          required
          displayError
        />

        <div>
          <TextFieldComponent
            label="New Password"
            name="newPassword"
            placeholder="Enter new password"
            className="width-90"
            required
            displayError
            type={showPassword ? 'text' : 'password'}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={this.handlePwdVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <TextFieldComponent
          label="Confirm Password"
          name="confirmPassword"
          placeholder="Re-enter new password"
          type="password"
          className="width-90"
          required
          displayError
        />
      </form>
    );
  };

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    loading: PropTypes.bool,
    hasError: PropTypes.bool,
    closeAlert: PropTypes.func,
    errordisplay: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    pristine: PropTypes.bool,
  };

  render() {
    const { onClose, title, pristine, loading } = this.props;

    const actions = [
      <Button
        key="cancel"
        type="button"
        color="secondary"
        onClick={onClose}
        size="small"
      >
        Cancel
      </Button>,
      <Button
        key="change"
        form="changePassword"
        type="submit"
        color="primary"
        size="small"
        disabled={pristine || loading}
      >
        Change
        {loading && <CircularProgress color="primary" size={15} />}
      </Button>,
    ];

    const content = this.getChangePasswordForm();
    return (
      <Dialog
        open={true}
        title={title || 'Change Password'}
        content={content}
        actions={actions}
        onClose={onClose}
      />
    );
  }
}

const requiredFields = ['currPassword', 'newPassword', 'confirmPassword'];

const validate = (values) => {
  const errors = {};

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = system.messages.FIELD_REQ;
    }
    if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = system.messages.PWD_AND_CONFIRM_PWD_DONT_MATCH;
    }
  });

  return errors;
};

export default reduxForm({
  form: 'change-pwd-form',
  validate,
})(ChangePasswordForm);
