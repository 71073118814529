const messages = {
  FIELD_REQ: 'This field is required',
  INVALID_EMAIL: "Email id should be in the format 'abc@pqr.com' ",
  LOGIN_COUNT_EXPECTED_TYPE_NUMB: 'Wrong Login count should be number',
  NOT_IN_WRONG_LOGIN_COUNT_RANGE:
    'Wrong Login count has to be in between 0 - 3',
  PWD_AND_CONFIRM_PWD_DONT_MATCH:
    'New password and confirm password dont match',
};

export default messages;
