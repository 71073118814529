import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import { App } from './components';
import { Provider } from 'react-redux';
import store from './store';
import theme from './theme/theme';
import utils from './utils/utils';
import { Router } from 'react-router-dom';
import './public/styles/styles.css';
import './images';
import { interceptor } from './common/actionInterceptor';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
interceptor(store.dispatch, history);
if (utils.helperFunctions.common.hasToken()) {
  utils.helperFunctions.common.handleLoggedInUser(store.dispatch);
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ReactNotification />
      <Router history={history}>
        <App />
      </Router>
    </ThemeProvider>
  </Provider>
);
