import { store } from 'react-notifications-component';

const alert = (options, message, duration) => {
  if (typeof message === 'string') {
    options.message = message;
  } else {
    options.content = message;
  }
  if (duration) {
    options.dismiss = {
      onScreen: true,
      pauseOnHover: true,
      duration: duration,
    };
  }
  options.insert = 'bottom';
  options.container = 'bottom-left';
  return store.addNotification(options);
};

const info = (message, alertId, duration = 5000, title = 'Info') => {
  store.removeNotification(alertId);
  const options = {
    title: title,
    type: 'info',
  };
  return alert(options, message, duration);
};

const success = (message, alertId, duration = 5000, title = 'Success') => {
  store.removeNotification(alertId);
  const options = {
    title: title,
    type: 'success',
  };
  return alert(options, message, duration);
};

const error = (message, alertId, duration = 5000, title = 'Error') => {
  store.removeNotification(alertId);
  const options = {
    title: title,
    type: 'danger',
  };
  return alert(options, message, duration);
};

const alerts = {
  info,
  success,
  error,
};

export default alerts;
