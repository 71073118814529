import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

export default function (ComposedComponent) {
  class AuthenticateUser extends React.Component {
    authenticateUser() {
      if (!this.props.isLoggedIn) {
        this.props.history.push('/', {
          urlRequested: this.props.location.pathname,
        });
      }
    }

    componentDidMount() {
      this.authenticateUser();
    }

    componentDidUpdate() {
      this.authenticateUser();
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  AuthenticateUser.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    userRole: PropTypes.string,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  function mapStateToProps(state) {
    return {
      isLoggedIn: state.auth.isLoggedIn,
    };
  }

  return connect(mapStateToProps)(withRouter(AuthenticateUser));
}
