import { deleteUser, searchUser } from '../apis';
import {
  deleteUserSucess,
  deleteUserFailure,
  searchFailed,
  searchResult,
} from '../actions/users';
import {
  DELETE_USER_STARTED,
  USER_SEARCH_STARTED,
} from '../common/actionTypes';
/**
 * apiMiddleware
 * @param {*} param0
 */
export const apiMiddleware = ({ getState, dispatch }) => (next) => async (
  action
) => {
  if (action.type === DELETE_USER_STARTED) {
    const { emailId } = action.payload;
    const {
      data: { success },
    } = await deleteUser({ emailId });

    if (success === true) {
      dispatch(deleteUserSucess({}));
    } else {
      dispatch(deleteUserFailure({}));
    }
  }

  if (action.type === USER_SEARCH_STARTED) {
    const { keyword, searchBy } = action.payload;
    const { data } = await searchUser({ searchBy, keyword });
    
    if (data.success === true) {
      dispatch(searchResult(data));
    } else {
      dispatch(searchFailed({}));
    }
  }

  return next(action);
};
