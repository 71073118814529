import { dispatch } from '../store/helper';
import {
  SET_MOBILE_DRAWER_STATE,
  TOGGLE_LOADER_STATE,
  SESSION_EXPIRE,
} from '../common/actionTypes';

export const dispatchMobileDrawerState = (payload) => ({
  type: SET_MOBILE_DRAWER_STATE,
  payload,
});

export const toggleLoaderAction = () =>
  dispatch({
    type: TOGGLE_LOADER_STATE,
  });

export const displayLogin = (payload) => {
  dispatch({
    type: SESSION_EXPIRE,
    payload,
  });
};
