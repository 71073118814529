import config from './config';
import constants from './constants';
import messages from './messageConstants';

const system = {
  constants,
  config,
  messages,
};

export default system;
