import React, { Component, Fragment } from 'react';
import { Card } from '@material-ui/core';
import { getTeamsAction } from '../../actions/teams';
import utils from '../../utils/utils';
import { toggleLoaderAction } from '../../actions/common';
import PageHeader from '../common/PageHeader';
import PropTypes from 'prop-types';
import Table from '../common/Table';
import SearchField from '../common/SearchField';

const {
  system: {
    constants: { PlACEHOLDERS },
  },
} = utils;

class Teams extends Component {
  state = {
    teams: [],
    pagination: { page: 1, total: 0 },
    currentPage: 1,
  };

  checkIfQueryRequired = (query) => {
    if (query.pageNo) {
      return true;
    }

    if (query.search) {
      return true;
    }

    return false;
  };

  getTeams = async (query) => {
    try {
      toggleLoaderAction();
      const { data } = await getTeamsAction(query);
      if (data.success && data.teamsList && data.teamsList.length) {
        this.setState({
          teams: data.teamsList,
          currentPage: Number(data.pagination.pageNo),
          pagination: data.pagination,
        });
      } else {
        this.setState({
          teams: [],
          currentPage: Number(data.pagination.pageNo),
          pagination: data.pagination,
        });
      }
      toggleLoaderAction();
    } catch (error) {
      toggleLoaderAction();
    }
  };

  getRowId = (row) => {
    return `${row.billingAcctId}/${row.teamId}`;
  };

  onPageChange = (nextPage) => {
    const parsedQs = utils.helperFunctions.common.getParsedQuery(
      this.props.history.location.search
    );
    parsedQs.pageNo = nextPage;
    const url = `?${utils.helperFunctions.common.getStringifiedQuery(
      parsedQs
    )}`;
    this.props.history.push(url);
  };

  onSearchHandler = (event) => {
    event.preventDefault();
    const parsedQs = utils.helperFunctions.common.getParsedQuery(
      this.props.history.location.search
    );
    parsedQs.search = event.target[0].value;
    parsedQs.pageNo = 1;
    const url = `?${utils.helperFunctions.common.getStringifiedQuery(
      parsedQs
    )}`;
    this.props.history.push(url);
  };

  isQueryUpdated = (prevParsedQs, currentParsedQs) => {
    if (prevParsedQs.pageNo !== currentParsedQs.pageNo) {
      return true;
    }

    if (prevParsedQs.search !== currentParsedQs.search) {
      return true;
    }

    return false;
  };

  componentDidMount = () => {
    const parsedQs = utils.helperFunctions.common.getParsedQuery(
      this.props.location.search
    );
    if (this.checkIfQueryRequired(parsedQs)) {
      this.getTeams(parsedQs);
    } else {
      this.getTeams();
    }
  };

  componentDidUpdate = (prevProps) => {
    const prevParsedQs = utils.helperFunctions.common.getParsedQuery(
      prevProps.location.search
    );
    const currentParsedQs = utils.helperFunctions.common.getParsedQuery(
      this.props.location.search
    );

    if (this.isQueryUpdated(prevParsedQs, currentParsedQs)) {
      this.getTeams(currentParsedQs);
    }
  };

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  render() {
    const { teams, currentPage, pagination } = this.state;

    const action = (
      <SearchField
        placeholder={PlACEHOLDERS.teamName}
        onSearch={this.onSearchHandler}
      />
    );

    const columns = [
      { id: 'teamName', label: 'Team Name' },
      { id: 'billingAcctName', label: 'Billing Account Name' },
      {
        id: 'createdAt',
        label: 'Created',
        format: (r) =>
          utils.helperFunctions.common.getFormattedDateInDays(r.createdAt),
      },
      {
        id: 'updatedAt',
        label: 'Updated',
        format: (r) =>
          utils.helperFunctions.common.getFormattedDateInDays(r.updatedAt),
      },
    ];

    return (
      <Fragment>
        <PageHeader title="Teams" actions={action} />
        <Card className="pd-10 mr-1rem">
          <Table
            rows={teams}
            columns={columns}
            rowIdKey={this.getRowId}
            page={currentPage}
            onPageChange={this.onPageChange}
            noRecordsMessage={<span>No Teams to display!</span>}
            total={pagination.total}
          />
        </Card>
      </Fragment>
    );
  }
}

export default Teams;
