import React, { Component } from 'react';
import Dialog from './Dialog';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

class DeletePrompt extends Component {
  state = {
    openDeleteDialog: false,
  };

  onDialogOpen = () =>
    this.setState((state) => ({
      openDeleteDialog: !state.openDeleteDialog,
    }));
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    entityDetails: PropTypes.object.isRequired,
    entity: PropTypes.string.isRequired,
  };
  render() {
    const { onClose, onDelete, entityDetails, entity } = this.props;
    const actions = [
      <Button
        key="No"
        type="button"
        color="secondary"
        onClick={onClose}
        size="small"
      >
        CANCEL
      </Button>,
      <Button
        key="Yes"
        type="button"
        color="secondary"
        onClick={onDelete}
        size="small"
      >
        CONFIRM
      </Button>,
    ];

    return (
      <Dialog
        open={true}
        title={`Delete ${entity} Confirmation`}
        content={
          <p>
            Do you want to delete the {entity.toLowerCase()}{' '}
            <b> {entityDetails.emailId} </b> ?
          </p>
        }
        actions={actions}
        onClose={onClose}
      />
    );
  }
}

export default DeletePrompt;
