import React, { Component, Fragment } from 'react';
import { Card, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeletePrompt from '../common/DeletePrompt';
import { getUsersAction } from '../../actions/users';
import { addUserToTeamAction } from '../../actions/teams';
import { adminUpdateUsersAction } from '../../actions/admin';
import utils from '../../utils/utils';
import { toggleLoaderAction } from '../../actions/common';
import PageHeader from '../common/PageHeader';
import PropTypes from 'prop-types';
import alerts from '../../utils/helperFunctions/alerts';
import Table from '../common/Table';
import UserForm from './UserForm';
import common from '../../utils/helperFunctions/common';
import Tools from '../common/Tools';
import {
  deleteUserStart,
  deleteUserCompleted,
  searchCompleted,
} from '../../actions/users';
import { connect } from 'react-redux';
import {
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
} from '../../common/actionTypes';
import { dispatch } from '../../store/helper';
import Dialog from '../../components/common/Dialog';
import SearchField from '../common/SearchField';
import SearchFilter from '../common/filter';

const {
  system: { constants },
} = utils;
const { MESSAGE } = constants;
export class Users extends Component {
  state = {
    users: [],
    userDetails: {},
    pagination: { page: 1, total: 0 },
    currentPage: 1,
    userType: 'new',
    openNewUserForm: false,
    hasError: false,
    errorDisplay: '',
    loading: false,
    openDeleteModal: false,
    userToDelete: {},
    popupTitle: '',
    filterBy: 'emailId',
  };

  static propTypes = {
    dispatch: PropTypes.func,
    deleteAction: PropTypes.func.isRequired,
    searchResult: PropTypes.func.isRequired,
    searchList: PropTypes.string.isRequired,
  };

  checkIfQueryRequired = (query) => {
    if (query.pageNo) {
      return true;
    }

    if (query.search) {
      return true;
    }

    return false;
  };
  handleUserList = (data) => {
    if (data.success && data.userList && data.userList.length) {
      this.setState({
        users: data.userList,
        currentPage: Number(data.pagination.pageNo),
        pagination: data.pagination,
      });
    } else {
      this.setState({
        users: [],
        currentPage: Number(data.pagination.pageNo),
        pagination: data.pagination,
      });
    }
  };
  getUsers = async (query) => {
    try {
      toggleLoaderAction();
      const { data } = await getUsersAction(query);
      this.handleUserList(data);

      toggleLoaderAction();
    } catch (error) {
      toggleLoaderAction();
    }
  };

  getRowId = (row) => {
    return `${row.billingId}/${row.teamId}/${row.userId}`;
  };

  onPageChange = (nextPage) => {
    const parsedQs = utils.helperFunctions.common.getParsedQuery(
      this.props.history.location.search
    );
    parsedQs.pageNo = nextPage;
    const url = `?${utils.helperFunctions.common.getStringifiedQuery(
      parsedQs
    )}`;
    this.props.history.push(url);
  };

  isQueryUpdated = (prevParsedQs, currentParsedQs) => {
    if (prevParsedQs.pageNo !== currentParsedQs.pageNo) {
      return true;
    }

    if (prevParsedQs.filterBy !== currentParsedQs.filterBy) {
      return true;
    }
    if (
      prevParsedQs[prevParsedQs.filterBy] !==
      currentParsedQs[currentParsedQs.filterBy]
    ) {
      return true;
    }

    return false;
  };

  toggleNewUserForm = () => {
    this.setState((prevState) => ({
      openNewUserForm: !prevState.openNewUserForm,
      loading: false,
      hasError: false,
      errordisplay: '',
    }));
  };

  handleAddMember = (event) => {
    event.preventDefault();
    this.toggleNewUserForm();
    this.setState({ userType: 'new' });
  };

  closeUserForm = () => {
    this.toggleNewUserForm();
  };

  closeAlert = () => this.setState({ hasError: false });

  addNewUser = async (values) => {
    this.setState({ hasError: false, errordisplay: '', loading: true });
    try {
      values.email = values.email.toLowerCase();
      values.email.trim();
      values.firstName.trim();
      values.lastName.trim();
      const { data } = await addUserToTeamAction(values);
      if (data && data.success) {
        alerts.success('Successfully Added User to Team');
        this.toggleNewUserForm();
        this.getUsers();
      } else {
        this.setState({
          hasError: true,
          errorDisplay:
            data.message || 'An Error occurred while Adding new User to Team',
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
      const message =
        common.getResponseFromError(error) ||
        'An Error occurred while Adding new User to Team. Please try again';
      this.setState({
        hasError: true,
        errorDisplay: message,
        loading: false,
      });
    }
  };

  onUpdate = async (row) => {
    this.toggleNewUserForm();
    this.setState({ userDetails: row, userType: 'old' });
  };

  updateUser = async (user) => {
    this.setState({ hasError: false, errordisplay: '', loading: true });
    try {
      const updateData = {
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        wrongLoginCount: user.wrongLoginCount,
      };
      const { data } = await adminUpdateUsersAction(updateData);
      if (data && data.success) {
        alerts.success(`Successfully Updated the User ${user.email}`);
        this.toggleNewUserForm();
        this.getUsers();
      } else {
        this.setState({
          hasError: true,
          errorDisplay: data.message || 'An Error occurred while updating',
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
      const message =
        common.getResponseFromError(error) ||
        'An Error occurred while Updating User. Please try again';
      this.setState({
        hasError: true,
        errorDisplay: message,
        loading: false,
      });
    }
  };

  componentDidMount = () => {
    const parsedQs = utils.helperFunctions.common.getParsedQuery(
      this.props.location.search
    );
    if (this.checkIfQueryRequired(parsedQs)) {
      this.getUsers(parsedQs);
    } else {
      this.getUsers();
    }
  };
  openDeleteModal = (row) => {
    this.setState(
      {
        openDeleteModal: true,
      },
      () => {
        this.setState({
          userToDelete: row,
        });
      }
    );
  };
  onDelete = () => {
    const { dispatch } = this.props;
    const { emailId } = this.state.userToDelete;

    dispatch(deleteUserStart({ emailId }));
    this.setState({ openDeleteModal: false });
  };
  /**
   * Show
   */
  showConfirmationPopup = (text) => {
    this.setState({ showDeleteConfirmation: true, popupTitle: text });
    setTimeout(() => {
      this.setState({
        showDeleteConfirmation: false,
        popupTitle: '',
      });
    }, 2000);
  };
  componentDidUpdate = (prevProps) => {
    const prevParsedQs = utils.helperFunctions.common.getParsedQuery(
      prevProps.location.search
    );
    const currentParsedQs = utils.helperFunctions.common.getParsedQuery(
      this.props.location.search
    );

    if (this.isQueryUpdated(prevParsedQs, currentParsedQs)) {
      this.getUsers(currentParsedQs);
    }
    if (this.props.deleteAction === DELETE_USER_SUCCESS) {
      this.showConfirmationPopup(MESSAGE.USER_DELETED_SUCCESS);
      dispatch(deleteUserCompleted({}));
      this.getUsers(currentParsedQs);
    }
    if (this.props.deleteAction === DELETE_USER_FAILED) {
      this.showConfirmationPopup(MESSAGE.USER_DELETED_FAILURE);
      dispatch(deleteUserCompleted({}));
      this.getUsers(currentParsedQs);
    }
    /**
     * This condition won't be of use for now ,kept if for refernce purpose
     * This type of design pattern would be helpful if someone wants to use
     * redux middleware for handling api.
     *
     */
    if (this.props.searchList.searchCompleted === true) {
      this.handleUserList(this.props.searchList);
      dispatch(searchCompleted({}));
    }
  };
  deleteBoxComponent = () => {
    return (
      <div>
        <p>User Deleted</p>
      </div>
    );
  };
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };
  onSearchHandler = (event) => {
    /**
     * TODO: need to know what does this function actually does.
     */
    event.preventDefault();

    const parsedQs = {};

    if (this.state.filterBy)
      parsedQs[this.state.filterBy] = event.target[0].value;
    parsedQs.pageNo = 1;
    parsedQs.filterBy = this.state.filterBy;

    const url = `?${utils.helperFunctions.common.getStringifiedQuery(
      parsedQs
    )}`;
    this.props.history.push(url);
    /**
     * Keeping it for future refernce
     * Reason for commenting as dispatch approch conflict on how search and pagination works
     *
     */
    // dispatch(startSearch({ searchBy, keyword }));
  };
  onFilter = (optionValue) => {
    this.setState({
      filterBy: optionValue,
    });
  };
  render() {
    const {
      users,
      userDetails,
      currentPage,
      pagination,
      openNewUserForm,
      userType,
      hasError,
      errorDisplay,
      loading,
      openDeleteModal,
      userToDelete,
      showDeleteConfirmation,
      popupTitle,
    } = this.state;

    const columns = [
      { id: 'emailId', label: 'Email Id' },
      { id: 'firstName', label: 'First Name' },
      { id: 'lastName', label: 'Last Name' },
      { id: 'billingName', label: 'Billing Account' },
      { id: 'teamName', label: 'Team' },

      {
        id: 'active',
        label: 'Password Set',
        format: (r) => (r.active ? 'true' : 'false'),
      },
      {
        id: 'createdAt',
        label: 'Created',
        format: (r) =>
          utils.helperFunctions.common.getFormattedDateInDays(r.createdAt),
      },
      {
        id: 'updatedAt',
        label: 'Updated',
        format: (r) =>
          utils.helperFunctions.common.getFormattedDateInDays(r.updatedAt),
      },
      { id: 'wrongLoginCount', label: 'Wrong Logins' },
      {
        id: 'accountExpiry',
        label: 'Account Expiry',
        format: (r) => r.accountExpiry || '--',
      },
      {
        id: 'appAccess',
        label: 'Status',
        format: (r) =>
          r.appAccess
            ? constants.USER_STATUS_DISPLAY_NAME.ACTIVE
            : constants.USER_STATUS_DISPLAY_NAME.IN_ACTIVE,
      },
      {
        id: 'tools',
        label: 'Tools',
        isToolTipNotRequired: true,
        format: (r) => (
          <Fragment>
            <div className="display-flex">
              <Tools
                row={r}
                onEdit={this.onUpdate}
                requiredTools={{ enableEdit: true }}
                entity={utils.system.constants.ENTITIES.USERS}
              />
              <Tools
                row={r}
                onDelete={this.openDeleteModal}
                requiredTools={{ enableDelete: true }}
                entity={utils.system.constants.ENTITIES.USERS}
              />
            </div>
          </Fragment>
        ),
      },
    ];

    const action = (
      <Fragment>
        <div className="display-flex">
          <div className="ml-2 mr-2">
            <SearchFilter onSelect={this.onFilter}></SearchFilter>
          </div>
          <SearchField
            placeholder={constants.PlACEHOLDERS[this.state.filterBy]}
            onSearch={this.onSearchHandler}
          ></SearchField>

          <Button
            onClick={this.handleAddMember}
            variant="contained"
            size="small"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add User
          </Button>
        </div>
      </Fragment>
    );

    return (
      <Fragment>
        <PageHeader title="Users" actions={action} />
        <Card className="pd-10 mr-1rem">
          <Table
            rows={users}
            columns={columns}
            rowIdKey={this.getRowId}
            page={currentPage}
            onPageChange={this.onPageChange}
            noRecordsMessage={
              <span>
                No Users!!. Let us Add some users by clicking <b>Add User</b>
              </span>
            }
            total={pagination.total}
          />
        </Card>
        {openNewUserForm && (
          <UserForm
            userDetails={userDetails}
            type={userType}
            hasError={hasError}
            errorDisplay={errorDisplay}
            loading={loading}
            closeAlert={this.closeAlert}
            onClose={this.closeUserForm}
            onSubmit={this.addNewUser}
            onEdit={this.updateUser}
          />
        )}
        {openDeleteModal && (
          <DeletePrompt
            onClose={() => {
              this.setState({ openDeleteModal: false, userToDelete: {} });
            }}
            onDelete={this.onDelete}
            entityDetails={userToDelete}
            entity="User"
          />
        )}
        {showDeleteConfirmation && (
          <Dialog open={true} title={popupTitle} disableBackdropClick={true} />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    deleteAction: state.user.deleteAction,
    searchList: state.user.searchResult,
  };
}

export default connect(mapStateToProps)(Users);
