// confirm the token storage name
const constants = {
  MESSAGE: {
    USER_DELETED_SUCCESS: 'User deleted !',
    USER_DELETED_FAILURE: 'User delete failed !',
  },
  TOKEN_STORAGE_NAME: 'romdtn',
  USER_ROLES: {
    APPLICATION_ADMIN: 'APPLICATION_ADMIN',
    BI_ADMIN: 'BI_ADMIN',
    TEAM_ADMIN: 'TEAM_ADMIN',
    USER: 'USER',
  },
  USER_STATUS: {
    ACTIVE: 'ACTIVE',
    IN_ACTIVE: 'IN_ACTIVE',
  },
  USER_STATUS_DISPLAY_NAME: {
    ACTIVE: 'Active',
    IN_ACTIVE: 'In-Active',
  },
  ENTITIES: {
    USERS: 'User',
    BILLING_ACCOUNT: 'Billing Account',
  },
  PlACEHOLDERS: {
    emailId: 'Search by Email id',
    billingName: 'Search by Account name',
    teamName: 'Search by Team`s name',
    EMAIL_ID: 'Email Id',
  },
  AUDIT_LOGS: {
    FILTER: {
      ADMIN_EMAIL: 'adminEmailId',
      ACTION: 'action',
      ENTITY: 'target',
    },
    ACTIONS: {
      ADD: 'ADD',
      DELETE: 'DELETE',
      UPDATE: 'UPDATE',
    },
    RESOURCE_TYPES: {
      BILLING_ACCOUNT: 'BILLING_ACCOUNT',
      TEAM: 'TEAM',
      USER: 'USER',
    },
  },
};

export default constants;
