import http from './requestor';

const baseUrl = 'api/v1/teams';

const getUrl = (endpoint) => `${baseUrl}${endpoint}`;

export const getTeamsAction = (query) => http.get(getUrl('/'), query);

export const addUserToTeamAction = (data) =>
  http.post(getUrl('/members'), data);
