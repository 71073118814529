import React, { Fragment, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Collapse,
} from '@material-ui/core';
import { debounce } from 'lodash';
import config from '../../utils/system/config';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import 'ace-builds';
import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

const getStyles = makeStyles(() => ({
  'textField.root': {
    display: 'flex',
    margin: '1rem',
  },
  'pd-l-10': {
    paddingLeft: '1rem',
  },

  fileUploadInput: {
    display: 'none',
  },
}));

const filterStyles = makeStyles(() => ({
  'textField.root': {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  inputLabel: {
    paddingLeft: '1.5rem',
    marginTop: '-0.5rem',
  },
}));

export const renderTextField = (field) => {
  const {
    meta: { touched, error },
    displayError,
  } = field;
  const classes = getStyles();
  const className = `${classes['textField.root']} ${field.className || ''}`;
  return (
    <TextField
      type={field.type}
      className={className}
      variant={field.variant || 'outlined'}
      id={field.name}
      label={field.label}
      placeholder={field.placeholder}
      error={!!(touched && error)}
      helperText={
        displayError && !!(touched && error)
          ? error
          : field.helperText
          ? field.helperText
          : ''
      }
      required={field.required}
      disabled={field.disabled}
      {...field.input}
      {...field.custom}
      InputProps={field.inputProps}
    />
  );
};

export const renderCheckboxField = (field) => {
  const { input, name, className, label } = field;
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={input.value ? true : false}
            onChange={input.onChange}
          />
        }
        className={className}
        label={label}
      />
    </div>
  );
};

export const renderNumberField = (field) => {
  const {
    meta: { touched, error },
  } = field;
  const className =
    touched && error ? `has-error ${field.className}` : field.className;
  return (
    <input
      type="number"
      className={className}
      placeholder={field.placeholder}
      {...field.input}
    />
  );
};

export const renderSelectField = (field) => {
  const classes = getStyles();
  const filterClass = filterStyles();
  const {
    meta: { touched, error, visited },
    deafultValue = '',
    formControlSize = 'medium',
    enableFilterCss = false,
    customOnChange,
    isControlledSelect = false,
    selectedValue,
  } = field;
  const hasError = !!(touched && error);
  const className = `${
    enableFilterCss ? filterClass['textField.root'] : classes['textField.root']
  } ${field.className || ''}`;
  return (
    <FormControl
      className={className}
      disabled={field.disabled}
      required={field.required}
      error={hasError}
      size={formControlSize}
    >
      <InputLabel
        ref={field.inputLabel}
        id={field.name}
        className={
          enableFilterCss ? filterClass.inputLabel : classes['pd-l-10']
        }
      >
        {field.label}
      </InputLabel>
      <Select
        labelId={field.name}
        id={field.name}
        variant="outlined"
        labelWidth={field.labelWidth}
        {...field.input}
        onChange={(event) => {
          if (customOnChange) customOnChange(event);
          field.input.onChange(event);
        }}
        value={
          isControlledSelect ? selectedValue : field.input.value || deafultValue
        }
      >
        {!!field.placeholder && (
          <MenuItem value="" disabled>
            {field.placeholder}
          </MenuItem>
        )}
        {field.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      <div>
        {visited && error && (
          <div className="color-red">{field.meta.error}</div>
        )}
        {field.helperText && (
          <span className="color-orange font-italic">{field.helperText}</span>
        )}
      </div>
    </FormControl>
  );
};

export const renderFileUploadField = (field) => {
  const classes = getStyles();
  const handleOnChange = (e) => {
    e.preventDefault();
    field.input.onChange(e.target.files[0]);
  };

  field.input.filename = field.input.value;
  delete field.input.value;

  const error = !!(field.meta.touched && field.meta.error);
  return (
    <div className={classes['textField.root']}>
      <input
        accept={field.accept}
        className={`${classes.fileUploadInput}`}
        id={field.id}
        type="file"
        disabled={field.disabled}
        {...field.input}
        onChange={handleOnChange}
      />
      <label htmlFor={field.id}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          component="span"
          className={error ? 'color-red' : ''}
        >
          Upload {field.label}
        </Button>
        {field.input.filename && (
          <span className="pd-10 color-green">{field.input.filename.name}</span>
        )}
        <div>
          {error && <div className="color-red">{field.meta.error}</div>}
          {field.helperText && (
            <span className="color-orange font-italic">{field.helperText}</span>
          )}
        </div>
      </label>
    </div>
  );
};

export const RenderAsyncAutocompleteTextField = (field) => {
  const {
    input,
    getAutoHandler,
    displayError,
    meta: { touched, error },
    customOnChange,
    formatRespData,
    reqParams = {},
    isClearSelection = false,
  } = field;
  const [open, setOpen] = useState(false);
  const [listOption, setListOption] = useState([]);
  const [selectOption, setSelectOption] = useState('');
  const loading = open && listOption.length === 0;

  const filterOptions = debounce(async (event, inputValue) => {
    if (!isClearSelection) {
      try {
        const { data } = await getAutoHandler({
          search: inputValue,
          ...reqParams,
        });
        setListOption(formatRespData(data));
      } catch (error) {
        console.log(error);
        setListOption([]);
      }
    } else {
      input.onChange('');
      setListOption([]);
      setSelectOption('');
    }
  }, config.DEBOUNCE_TIME);

  useEffect(() => {
    if (!loading) {
      return undefined;
    }
    filterOptions();
  }, [loading, filterOptions]);

  useEffect(() => {
    if (!open) {
      setListOption([]);
    }
  }, [open]);

  return (
    <Fragment>
      <Autocomplete
        key={isClearSelection}
        id={input.name}
        {...input}
        inputValue={selectOption}
        className={field.className}
        size={field.size}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={listOption}
        loading={loading}
        name={input.name}
        disabled={field.disabled}
        required={field.required}
        autoHighlight={true}
        clearOnBlur={field.freeSolo ? true : false}
        onBlur={() => input.onBlur()}
        popupIcon={field.popupIcon}
        forcePopupIcon={field.forcePopupIcon}
        onChange={(event, newValue) => {
          if (customOnChange) {
            customOnChange(newValue);
          }
          input.onChange(newValue?.value ? newValue.value : newValue);
          setSelectOption(
            isClearSelection || !newValue?.value ? '' : newValue?.label
          );
        }}
        onInputChange={(event, value) => {
          event && setSelectOption(value);
          filterOptions(event, value);
        }}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={field.getOptionLabel}
        freeSolo={field.freeSolo}
        filterOptions={field.filterOptions}
        renderOption={field.renderOption}
        renderInput={(params) => {
          if (isClearSelection) params.inputProps.value = '';
          return (
            <TextField
              {...params}
              label={field.label}
              variant="outlined"
              error={!!(touched && error)}
              helperText={
                displayError && !!(touched && error)
                  ? error
                  : field.helperText
                  ? field.helperText
                  : ''
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          );
        }}
      />
    </Fragment>
  );
};

export const RenderJsonEditor = (field) => {
  const {
    input,
    clearInput,
    clickOkValue,
    editorHeight,
    editorWidth,
    value,
    onChange,
    isUpdate,
    initialValue = '',
    isCleared,
    isNewValue = false,
    passUpdatedValue,
    isClosed,
    isNewPackageCreation,
  } = field;
  const [editorValue, setEditorValue] = useState('');

  const changehandler = (value) => {
    setEditorValue(value);
  };

  useEffect(() => {
    function saveToState(data) {
      setEditorValue(data);
      input.onChange(data);
    }
    if (isUpdate && initialValue !== '' && !isCleared && isNewValue) {
      saveToState(initialValue);
    }
    if (!isUpdate && isClosed && input.value !== editorValue) {
      saveToState(input.value);
    }
    if (!isNewPackageCreation && !isUpdate && input.value !== '') {
      saveToState('');
    }
  });

  useEffect(() => {
    function saveToState(data) {
      input.onChange(data);
      isUpdate && passUpdatedValue(data);
    }
    clickOkValue && saveToState(editorValue);
  });

  if (clearInput && editorValue !== '') {
    setEditorValue('');
  }

  return (
    <Fragment>
      <AceEditor
        mode="javascript"
        theme="github"
        onChange={onChange ? onChange : (value) => changehandler(value)}
        name={input.name}
        showPrintMargin={true}
        value={value ? value : editorValue}
        setOptions={{
          showLineNumbers: true,
        }}
        height={editorHeight || '300px'}
        width={editorWidth || '711px'}
        readOnly={field.disabled}
      />
    </Fragment>
  );
};

export const RenderAlertComponent = (props) => {
  return (
    <div className="alert-common-div">
      <Collapse in={props.open}>
        <Alert
          icon={props.hasIcon && false}
          severity={props.severity ? props.severity : 'error'}
          action={
            props.hasIcon ? null : (
              <IconButton
                aria-label="close"
                color={props.color ? props.color : 'secondary'}
                size="small"
                onClick={props.onClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
        >
          {props.children}
        </Alert>
      </Collapse>
    </div>
  );
};

RenderAlertComponent.propTypes = {
  severity: PropTypes.string,
  color: PropTypes.string,
  action: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.string,
  hasIcon: PropTypes.bool,
};
