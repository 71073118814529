import React, { Component } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import { fade } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const stylesCallback = (theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    height: '2.5rem',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '75%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    height: '2.5rem',
  },
  inputInput: {
    // padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 80,
      '&:focus': {
        width: 180,
      },
    },
  },
});

export class SearchField extends Component {
  state = {
    searchText: '',
  };
  sumbitOnInputChange = (event) => {
    event.preventDefault();
    this.setState({
      searchText: event.target[0].value,
    });
    this.props.onSearch(event);
  };

  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    classes: PropTypes.object,
    placeholder: PropTypes.string,
  };

  render() {
    const { classes, placeholder } = this.props;
    return (
      <div className={classes.root}>
        <Paper component="form" onSubmit={this.sumbitOnInputChange}>
          <div className={classes.search}>
            <InputBase
              placeholder={placeholder ? placeholder : 'Search..'}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton type="submit" aria-label="search" size="small">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        </Paper>
      </div>
    );
  }
}

export default withStyles(stylesCallback)(SearchField);
