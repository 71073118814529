import React, { Component } from 'react';
import { Typography } from '@material-ui/core';

class NotFoundComponent extends Component {
  render() {
    return (
      <div className="header-wrap">
        <div className="container-align-center-body">
          <Typography variant="h4">Route Not Found</Typography>
        </div>
      </div>
    );
  }
}

export default NotFoundComponent;
