import React from 'react';
import { connect } from 'react-redux';

export default function (ComposedComponent) {
  class InjectLoggedInUser extends React.Component {
    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      isLoggedIn: state.auth.isLoggedIn,
      user: state.auth.user,
    };
  }

  return connect(mapStateToProps)(InjectLoggedInUser);
}
