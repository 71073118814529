import http from './requestor';

const baseUrl = 'api/v1/billing_account';

const getUrl = (endpoint) => `${baseUrl}${endpoint}`;

export const getBillingAcctAction = (query) => http.get(getUrl('/'), query);

export const createBillingAcctAction = (data) => http.post(getUrl('/'), data);

export const updateBillingAcctAction = (data) => http.put(getUrl('/'), data);
