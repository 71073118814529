import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: '99999999',
  },
}));

const Loader = () => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    function tick() {
      // reset when reaching 100%
      let randomInterval = Math.random();
      setProgress((oldProgress) =>
        oldProgress >= 100 ? 0 : oldProgress + randomInterval
      );
    }
    const timer = setInterval(tick, 300);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <LinearProgress
      color="primary"
      className={classes.root}
      variant="determinate"
      value={progress}
    />
  );
};

export const ProgressLoader = (props) => {
  if (props.isLoading) {
    return <Loader />;
  }
  return null;
};

ProgressLoader.propTypes = {
  isLoading: PropTypes.bool,
};
