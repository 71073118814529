import {
  SET_MOBILE_DRAWER_STATE,
  TOGGLE_LOADER_STATE,
  SESSION_EXPIRE,
} from '../common/actionTypes';

const initialState = false;

export const mobileDrawerState = (state = initialState, action = {}) => {
  let currentState;
  switch (action.type) {
    case SET_MOBILE_DRAWER_STATE:
      currentState = state;
      return !currentState;
    default:
      return state;
  }
};

export const toggleLoader = (state = initialState, action = {}) => {
  let currentState;
  switch (action.type) {
    case TOGGLE_LOADER_STATE:
      currentState = state;
      return !currentState;
    default:
      return state;
  }
};

export const showLogin = (state = initialState, action = {}) => {
  switch (action.type) {
    case SESSION_EXPIRE:
      state = action.payload;
      return state;
    default:
      return state;
  }
};
