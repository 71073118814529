import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { reduxForm } from 'redux-form';
import Dialog from '../common/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';
import {
  TextFieldComponent,
  SelectFieldComponent,
  AsyncAutocompleteTextField,
  AlertComponent,
} from '../common/formComponents';
import CircularProgress from '@material-ui/core/CircularProgress';
import system from '../../utils/system/system';
import PropTypes from 'prop-types';
import { getBillingAcctAction } from '../../actions/billingAccounts';
import { getTeamsAction } from '../../actions/teams';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const rolesOptions = [
  {
    value: system.constants.USER_ROLES.BI_ADMIN,
    name: 'Billing Account Admin',
  },
  { value: system.constants.USER_ROLES.TEAM_ADMIN, name: 'Team Admin' },
  { value: system.constants.USER_ROLES.USER, name: 'User' },
];

class UserForm extends Component {
  state = {
    type: 'new',
    showPassword: false,
    values: '',
    disableTeams: true,
    currBillingId: '',
  };

  onFromSubmit = (values) => {
    const { onSubmit, onEdit, type } = this.props;
    if (type === 'old') {
      return onEdit(values);
    }
    onSubmit(values);
  };

  handleBillingAcctsResp = (data) => {
    if (data && data.billingAccountsList) {
      return data.billingAccountsList.map((obj) => {
        return { name: obj.accounName, label: obj.accounName, value: obj.id };
      });
    }
    return [];
  };

  handleTeamIdResp = (data) => {
    if (data && data.teamsList) {
      return data.teamsList.map((obj) => {
        return { name: obj.teamName, label: obj.teamName, value: obj.teamId };
      });
    }
    return [];
  };

  onBillingAcctChange = (valObj) => {
    this.setState({
      currBillingId: valObj && valObj.value ? valObj.value : '',
      disableTeams: valObj && valObj.value ? false : true,
    });
  };

  getUserForm = () => {
    const { handleSubmit, hasError, errorDisplay, closeAlert } = this.props;
    const { type, disableTeams, currBillingId } = this.state;

    return (
      <form onSubmit={handleSubmit(this.onFromSubmit)} id="createUser">
        {hasError && (
          <AlertComponent open={hasError} onClose={closeAlert}>
            {errorDisplay}
          </AlertComponent>
        )}
        <TextFieldComponent
          label="First Name"
          name="firstName"
          placeholder="User display name"
          className="width-90"
          required
        />

        <TextFieldComponent
          label="Last Name"
          name="lastName"
          placeholder="User display name"
          className="width-90"
          required
        />

        <TextFieldComponent
          label="Email"
          name="email"
          placeholder="Email-id will be used for login"
          className="width-90"
          required
          displayError
          disabled={type === 'old'}
        />

        {type !== 'old' && (
          <SelectFieldComponent
            label="User Role"
            name="role"
            placeholder="User Role Admin/User"
            className="width-90"
            options={rolesOptions}
            disabled={type === 'old'}
            required
          />
        )}

        {type !== 'old' && (
          <>
            <div className="display-flex ">
              <div className="padding-1rem width-100">
                <AsyncAutocompleteTextField
                  name="billingAccount"
                  label="Billing Account"
                  id="billingAccount"
                  size="small"
                  className="width-100"
                  disabled={type === 'old'}
                  required
                  displayError
                  getAutoHandler={getBillingAcctAction}
                  freeSolo={false}
                  popupIcon={<ArrowDropDownIcon />}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    return (option && option.label) || '';
                  }}
                  formatRespData={this.handleBillingAcctsResp}
                  customOnChange={this.onBillingAcctChange}
                  renderOption={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    } else return option.label;
                  }}
                />
              </div>

              <div className="flex-align-self-center">
                {type !== 'old' && (
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography variant="caption">
                          * Select Billing Account
                        </Typography>
                        <br />
                        <Typography variant="caption">
                          * Create Billing Account if doesnt exist
                        </Typography>
                        <br />
                      </React.Fragment>
                    }
                    placement="right-start"
                    arrow
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                )}
              </div>
            </div>

            <div className="display-flex ">
              <div className="padding-1rem width-100">
                <AsyncAutocompleteTextField
                  name="teamId"
                  label="Team Id"
                  id="teamId"
                  size="small"
                  className="width-100"
                  disabled={type === 'old' || disableTeams}
                  required
                  displayError
                  getAutoHandler={getTeamsAction}
                  freeSolo={false}
                  popupIcon={<ArrowDropDownIcon />}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    return (option && option.name) || '';
                  }}
                  formatRespData={this.handleTeamIdResp}
                  reqParams={{ billingId: currBillingId }}
                  isClearSelection={disableTeams}
                  renderOption={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    } else return option.label;
                  }}
                />
              </div>

              <div className="flex-align-self-center">
                {type !== 'old' && (
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography variant="caption">
                          * Select existing team of a Billing account
                        </Typography>
                        <br />
                      </React.Fragment>
                    }
                    placement="right-start"
                    arrow
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                )}
              </div>
            </div>
          </>
        )}

        {type === 'old' && (
          <TextFieldComponent
            label="Wrong Logins"
            name="wrongLoginCount"
            placeholder="Wrong Login Counts"
            className="width-90"
            displayError
            required
          />
        )}
      </form>
    );
  };

  handleUpdate = () => {
    const { userDetails, type, initialize } = this.props;
    if (type === 'old' && userDetails) {
      userDetails.email = userDetails.emailId;
      this.setState({ type });
      initialize(userDetails);
    }
  };

  componentDidMount = () => {
    this.handleUpdate();
  };

  static propTypes = {
    userDetails: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    initialize: PropTypes.func,
    pristine: PropTypes.bool,
    hasError: PropTypes.bool,
    errorDisplay: PropTypes.string,
    closeAlert: PropTypes.func,
    loading: PropTypes.bool,
  };

  render() {
    const { onClose, title, pristine, type, loading } = this.props;

    const actions = [
      <Button
        key="cancel"
        type="button"
        color="secondary"
        onClick={onClose}
        size="small"
        disabled={loading}
      >
        Cancel
      </Button>,
      <Button
        key="add"
        form="createUser"
        type="submit"
        color="primary"
        size="small"
        disabled={pristine || loading}
      >
        {type === 'old' ? 'Update' : 'Add'}
        {loading && <CircularProgress color="primary" size={15} />}
      </Button>,
    ];
    const content = this.getUserForm();
    return (
      <Dialog
        open={true}
        title={title || type === 'old' ? 'Update User' : 'Add User To Team'}
        content={content}
        actions={actions}
        onClose={onClose}
        disableBackdropClick={true}
      />
    );
  }
}

const requireFields = [
  'email',
  'billingAccount',
  'teamId',
  'role',
  'firstName',
  'lastName',
];

const validate = (values) => {
  const errors = {};
  const emailReg = RegExp(/^[a-zA-Z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);

  requireFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = system.messages.FIELD_REQ;
    }
    if (values.email && !emailReg.test(values.email)) {
      errors.email = system.messages.INVALID_EMAIL;
    }
    if (values.wrongLoginCount && isNaN(values.wrongLoginCount)) {
      errors.wrongLoginCount = system.messages.LOGIN_COUNT_EXPECTED_TYPE_NUMB;
    }
    if (
      values.wrongLoginCount &&
      (values.wrongLoginCount < 0 || values.wrongLoginCount > 3)
    ) {
      errors.wrongLoginCount = system.messages.NOT_IN_WRONG_LOGIN_COUNT_RANGE;
    }
  });

  return errors;
};

export default reduxForm({
  form: 'userFrom', // a unique identifier for this form
  validate,
})(UserForm);
