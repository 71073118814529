import { SET_CURRENT_USER } from '../common/actionTypes';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  isLoggedIn: false,
  userRole: '',
  subscriptionStatus: '',
  user: {},
};

export const loggedInUser = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        isLoggedIn: !isEmpty(action.payload),
        userRole: !isEmpty(action.payload) ? action.payload.userRole : '',
        userId: !isEmpty(action.payload) ? action.payload.userId : '',
        name: !isEmpty(action.payload) ? action.payload.name : '',
        user: action.payload,
      };
    default:
      return state;
  }
};
