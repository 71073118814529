import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const stylesCallback = () => ({
  dialogRoot: {
    width: '40rem',
  },
});

class CustomDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    content: PropTypes.object,
    open: PropTypes.bool,
    dividers: PropTypes.bool,
    maxWidth: PropTypes.string,
    actions: PropTypes.array,
    scroll: PropTypes.string,
    disableBackdropClick: PropTypes.bool,
  };
  render() {
    const {
      onClose,
      title,
      content,
      actions,
      open = true,
      maxWidth,
      dividers = true,
      scroll,
      disableBackdropClick = false,
    } = this.props;
    return (
      <Dialog
        onKeyDown={(event) => {
          if (event.key === 'Tab') {
            event.stopPropagation();
          }
        }}
        open={open}
        onClose={onClose}
        scroll={scroll || 'paper'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        disableBackdropClick={disableBackdropClick}
        maxWidth={maxWidth || 'sm'}
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        {content && (
          <DialogContent dividers={!dividers}>{content}</DialogContent>
        )}
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    );
  }
}

export default withStyles(stylesCallback)(CustomDialog);
