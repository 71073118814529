import React from 'react';
import { makeStyles, Typography, Card } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
}));

const PageHeader = (props) => {
  const classes = useStyles();
  return (
    <Card className="pd-10 mr-1rem">
      <div className="flex-flow-wrap">
        <Typography variant="h5" className={classes.title}>
          {props.title}
        </Typography>
        <div className="flex-flow-wrap actions-div-ml-10">{props.actions}</div>
      </div>
    </Card>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.object,
};

export default PageHeader;
