import React from 'react';
import { Field } from 'redux-form';
import {
  renderTextField,
  renderCheckboxField,
  renderSelectField,
  renderFileUploadField,
  RenderJsonEditor,
  RenderAlertComponent,
  RenderAsyncAutocompleteTextField,
} from './renderField';
// import { renderSelectFieldWithCustomOnChange } from '../components/pages/storepepTeam/reduxFormRenderFunctions';
import PropTypes from 'prop-types';

export const TextFieldComponent = (props) => {
  return (
    <Field
      className={props.className}
      name={props.name}
      label={props.label}
      placeholder={props.placeholder}
      component={renderTextField}
      type={props.type || 'text'}
      required={!!props.required}
      custom={props.custom || {}}
      displayError={!!props.displayError}
      disabled={!!props.disabled}
      inputProps={props.inputProps}
      helperText={props.helperText}
    />
  );
};

TextFieldComponent.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  custom: PropTypes.object,
  displayError: PropTypes.bool,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  helperText: PropTypes.any,
};

export const CheckboxComponent = (props) => {
  return (
    <div className={props.containerDivClassName || 'halfWidth'}>
      <Field
        className={props.className || 'checkbox-custom'}
        name={props.name}
        id={props.name}
        label={props.label}
        component={renderCheckboxField}
      />
      <label htmlFor={props.name} className="checkbox-custom-label">
        {props.labelName}
      </label>
    </div>
  );
};

CheckboxComponent.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  containerDivClassName: PropTypes.string,
  labelName: PropTypes.string,
};

export const SelectFieldComponent = (props) => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  return (
    <Field
      name={props.name}
      label={props.label}
      className={props.className}
      component={renderSelectField}
      options={props.options}
      placeholder={props.placeholder || false}
      onChange={props.onChange}
      disabled={!!props.disabled}
      required={!!props.required}
      inputLabel={inputLabel}
      labelWidth={labelWidth}
      deafultValue={props.deafultValue}
      formControlSize={props.formControlSize}
      enableFilterCss={props.enableFilterCss}
      customOnChange={props.customOnChange}
      isControlledSelect={props.isControlledSelect}
      selectedValue={props.selectedValue}
    />
  );
};

SelectFieldComponent.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  deafultValue: PropTypes.string,
  formControlSize: PropTypes.string,
  enableFilterCss: PropTypes.bool,
  customOnChange: PropTypes.func,
  isControlledSelect: PropTypes.bool,
  selectedValue: PropTypes.string,
};

export const FileUploadComponent = (props) => {
  return (
    <Field
      name={props.name}
      accept={props.accept}
      id={props.name}
      label={props.label}
      helperText={props.helperText}
      component={renderFileUploadField}
      disabled={!!props.disabled}
      required={!!props.required}
    />
  );
};

FileUploadComponent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  accept: PropTypes.string.isRequired,
  helperText: PropTypes.any,
};

export const AsyncAutocompleteTextField = (props) => {
  return (
    <Field
      name={props.name}
      id={props.id}
      component={RenderAsyncAutocompleteTextField}
      label={props.label}
      className={props.className}
      disabled={props.disabled}
      required={!!props.required}
      displayError={!!props.displayError}
      getAutoHandler={props.getAutoHandler}
      popupIcon={props.popupIcon}
      forcePopupIcon={props.forcePopupIcon}
      freeSolo={props.freeSolo}
      renderOption={props.renderOption}
      size={props.size}
      filterOptions={props.filterOptions}
      getOptionLabel={props.getOptionLabel}
      customOnChange={props.customOnChange}
      formatRespData={props.formatRespData}
      reqParams={props.reqParams}
      isClearSelection={props.isClearSelection}
    />
  );
};

AsyncAutocompleteTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  displayError: PropTypes.bool,
  getAutoHandler: PropTypes.func.isRequired,
  popupIcon: PropTypes.node,
  forcePopupIcon: PropTypes.bool,
  freeSolo: PropTypes.bool,
  renderOption: PropTypes.func,
  size: PropTypes.string,
  filterOptions: PropTypes.func,
  getOptionLabel: PropTypes.func,
  customOnChange: PropTypes.func,
  formatRespData: PropTypes.func,
  reqParams: PropTypes.object,
  isClearSelection: PropTypes.bool,
};

export const JsonEditor = (props) => {
  return (
    <Field
      name={props.name}
      id={props.id}
      component={RenderJsonEditor}
      label={props.label}
      clearInput={props.clearJson}
      passUpdatedValue={props.passUpdatedValue}
      clickOkValue={props.clickOkValue}
      editorHeight={props.editorHeight}
      editorWidth={props.editorWidth}
      value={props.value}
      onChange={props.onChange}
      isUpdate={props.isUpdate}
      initialValue={props.initialValue}
      isCleared={props.isCleared}
      isNewValue={props.isNewValue}
      disabled={props.disabled}
      isClosed={props.isClosed}
      isNewPackageCreation={props.isNewPackageCreation}
    />
  );
};

JsonEditor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  clearJson: PropTypes.bool,
  passUpdatedValue: PropTypes.func,
  clickOkValue: PropTypes.bool,
  editorHeight: PropTypes.string,
  editorWidth: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isUpdate: PropTypes.bool,
  initialValue: PropTypes.string,
  isCleared: PropTypes.bool,
  isNewValue: PropTypes.bool,
  disabled: PropTypes.bool,
  isClosed: PropTypes.bool,
  isNewPackageCreation: PropTypes.bool,
};

export const AlertComponent = (props) => {
  return <RenderAlertComponent {...props} />;
};

AlertComponent.propTypes = {
  severity: PropTypes.string,
  color: PropTypes.string,
  action: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.string,
  hasIcon: PropTypes.bool,
};
