import React, { Component, Fragment } from 'react';
import { Card, Button } from '@material-ui/core';
import {
  getBillingAcctAction,
  createBillingAcctAction,
  updateBillingAcctAction,
} from '../../actions/billingAccounts';
import utils from '../../utils/utils';
import { toggleLoaderAction } from '../../actions/common';
import PageHeader from '../common/PageHeader';
import PropTypes from 'prop-types';
import Table from '../common/Table';
import BillingAccountForm from './BillingAccountForm';
import AddIcon from '@material-ui/icons/Add';
import alerts from '../../utils/helperFunctions/alerts';
import common from '../../utils/helperFunctions/common';
import SearchField from '../common/SearchField';
import Tools from '../common/Tools';

const {
  system: {
    constants: { PlACEHOLDERS, USER_STATUS_DISPLAY_NAME },
  },
} = utils;

export class BillingAccounts extends Component {
  state = {
    openBillingAcctDialog: false,
    billingAcctDetails: {},
    acctType: 'new',
    billingAccounts: [],
    pagination: { page: 1, total: 0 },
    currentPage: 1,
    loading: false,
    hasError: false,
    errorDisplay: '',
  };
  checkIfQueryRequired = (query) => {
    if (query.pageNo) {
      return true;
    }

    if (query.search) {
      return true;
    }

    return false;
  };

  getBillingAccounts = async (query) => {
    try {
      toggleLoaderAction();
      const { data } = await getBillingAcctAction(query);
      if (
        data.success &&
        data.billingAccountsList &&
        data.billingAccountsList.length
      ) {
        this.setState({
          billingAccounts: data.billingAccountsList,
          currentPage: Number(data.pagination.pageNo),
          pagination: data.pagination,
        });
      } else {
        this.setState({
          billingAccounts: [],
          currentPage: Number(data.pagination.pageNo),
          pagination: data.pagination,
        });
      }
      toggleLoaderAction();
    } catch (error) {
      toggleLoaderAction();
    }
  };

  getRowId = (row) => {
    return `${row.id}`;
  };

  onPageChange = (nextPage) => {
    const parsedQs = utils.helperFunctions.common.getParsedQuery(
      this.props.history.location.search
    );
    parsedQs.pageNo = nextPage;
    const url = `?${utils.helperFunctions.common.getStringifiedQuery(
      parsedQs
    )}`;
    this.props.history.push(url);
  };

  onSearchHandler = (event) => {
    event.preventDefault();
    const parsedQs = utils.helperFunctions.common.getParsedQuery(
      this.props.history.location.search
    );
    parsedQs.search = event.target[0].value;
    parsedQs.pageNo = 1;
    const url = `?${utils.helperFunctions.common.getStringifiedQuery(
      parsedQs
    )}`;
    this.props.history.push(url);
  };

  isQueryUpdated = (prevParsedQs, currentParsedQs) => {
    if (prevParsedQs.pageNo !== currentParsedQs.pageNo) {
      return true;
    }

    if (prevParsedQs.search !== currentParsedQs.search) {
      return true;
    }

    return false;
  };

  toggleBillingAcctForm = () => {
    this.setState((prevState) => ({
      openBillingAcctDialog: !prevState.openBillingAcctDialog,
      loading: false,
      hasError: false,
      errorDisplay: '',
    }));
  };

  closeAlert = () => this.setState({ hasError: false });

  closeAcctForm = () => {
    this.toggleBillingAcctForm();
  };

  handleNewBillingAcct = (event) => {
    event.preventDefault();
    this.setState({ acctType: 'new' });
    this.toggleBillingAcctForm();
  };

  addNewBillingAcct = async (values) => {
    this.setState({ hasError: false, errordisplay: '', loading: true });
    try {
      const { data } = await createBillingAcctAction(values);
      if (data && data.success) {
        alerts.success('Successfully created Billing Account');
        this.getBillingAccounts();
        this.toggleBillingAcctForm();
      } else {
        this.setState({
          hasError: true,
          errorDisplay: data.message,
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
      const message =
        common.getResponseFromError(error) ||
        'An Error occurred while creating new Billing Account. Please try again';

      this.setState({
        hasError: true,
        errorDisplay: message,
        loading: false,
      });
    }
  };

  onUpdate = (row) => {
    this.toggleBillingAcctForm();
    this.setState({ billingAcctDetails: row, acctType: 'old' });
  };

  updateBillingAcct = async (values) => {
    this.setState({ hasError: false, errordisplay: '', loading: true });
    try {
      const updateObj = { billAcctId: values.id, isActive: values.isActive };
      const { data } = await updateBillingAcctAction(updateObj);
      if (data && data.success) {
        alerts.success(
          `Successfully Updated the ${values.accounName} Billing Account`
        );
        this.toggleBillingAcctForm();
        this.getBillingAccounts();
      } else {
        this.setState({
          hasError: true,
          errorDisplay: data.message || 'An Error occurred while updating',
          loading: false,
        });
      }
    } catch (error) {
      const message =
        common.getResponseFromError(error) ||
        'An Error occurred while Updating Billing Account. Please try again';
      this.setState({
        hasError: true,
        errorDisplay: message,
        loading: false,
      });
    }
  };

  componentDidMount = () => {
    const parsedQs = utils.helperFunctions.common.getParsedQuery(
      this.props.location.search
    );
    if (this.checkIfQueryRequired(parsedQs)) {
      this.getBillingAccounts(parsedQs);
    } else {
      this.getBillingAccounts();
    }
  };

  componentDidUpdate = (prevProps) => {
    const prevParsedQs = utils.helperFunctions.common.getParsedQuery(
      prevProps.location.search
    );
    const currentParsedQs = utils.helperFunctions.common.getParsedQuery(
      this.props.location.search
    );

    if (this.isQueryUpdated(prevParsedQs, currentParsedQs)) {
      this.getBillingAccounts(currentParsedQs);
    }
  };

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  render() {
    const {
      billingAccounts,
      billingAcctDetails,
      acctType,
      currentPage,
      pagination,
      hasError,
      errorDisplay,
      loading,
      openBillingAcctDialog,
    } = this.state;

    const action = (
      <Fragment>
        <SearchField
          placeholder={PlACEHOLDERS.billingName}
          onSearch={this.onSearchHandler}
        ></SearchField>
        <div>
          <Button
            onClick={this.handleNewBillingAcct}
            variant="contained"
            size="small"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add Billing Account
          </Button>
        </div>
      </Fragment>
    );

    const columns = [
      { id: 'accounName', label: 'Account Name' },
      { id: 'email', label: 'Email Id' },
      { id: 'org', label: 'Organization' },
      {
        id: 'createdAt',
        label: 'Created',
        format: (r) =>
          utils.helperFunctions.common.getFormattedDateInDays(r.createdAt),
      },
      {
        id: 'updatedAt',
        label: 'Updated',
        format: (r) =>
          utils.helperFunctions.common.getFormattedDateInDays(r.updatedAt),
      },
      {
        id: 'active',
        label: 'Status',
        format: (r) =>
          r.active
            ? USER_STATUS_DISPLAY_NAME.ACTIVE
            : USER_STATUS_DISPLAY_NAME.IN_ACTIVE,
      },
      {
        id: 'tools',
        label: 'Tools',
        isToolTipNotRequired: true,
        format: (r) => (
          <Tools
            row={r}
            onEdit={this.onUpdate}
            requiredTools={{ enableEdit: true }}
            entity={utils.system.constants.ENTITIES.BILLING_ACCOUNT}
          />
        ),
      },
    ];

    return (
      <Fragment>
        <PageHeader title="Billing Accounts" actions={action} />
        <Card className="pd-10 mr-1rem">
          <Table
            rows={billingAccounts}
            columns={columns}
            rowIdKey={this.getRowId}
            page={currentPage}
            onPageChange={this.onPageChange}
            noRecordsMessage={
              <span>
                No Billing Account!!. Let us Add a Billing Account by clicking{' '}
                <b>Add Billing Account</b>
              </span>
            }
            total={pagination.total}
          />
        </Card>
        {openBillingAcctDialog && (
          <BillingAccountForm
            billingAcctDetails={billingAcctDetails}
            type={acctType}
            hasError={hasError}
            errorDisplay={errorDisplay}
            closeAlert={this.closeAlert}
            loading={loading}
            onClose={this.closeAcctForm}
            onSubmit={this.addNewBillingAcct}
            onEdit={this.updateBillingAcct}
          />
        )}
      </Fragment>
    );
  }
}

export default BillingAccounts;
