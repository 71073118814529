import React from 'react';
import { connect } from 'react-redux';

export default function (ComposedComponent) {
  class InjectLoaderStatus extends React.Component {
    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      enableLoader: state.enableLoader,
    };
  }

  return connect(mapStateToProps)(InjectLoaderStatus);
}
