import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#f59600' },
    secondary: { main: '#f5b653' },
  },
  // status: {
  //     danger: 'orange',
  // },
  // overrides: {
  //     MuiInput: {
  //         underline: {
  //             '&:after': {
  //                 backgroundColor: 'orange',
  //                 borderColor: 'green'
  //             }
  //         },
  //     },
  // }
});

export default theme;
