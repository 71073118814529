import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Paper, Button, ButtonGroup } from '@material-ui/core';
import { TextFieldComponent, AlertComponent } from '../common/formComponents';
import { loginAction } from '../../actions/auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import utils from '../../utils/utils';
import PropTypes from 'prop-types';
import constants from '../../utils/system/constants';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      hasError: false,
      emailError: '',
      errorMessage: '',
      isLoginLoading: false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  async onFormSubmit(values) {
    this.setState({ hasError: false, isLoginLoading: true, errorMessage: '' });
    try {
      values.username.trim();
      values.pwd.trim();
      const { data } = await loginAction(values, this.props.dispatch);
      if (this.props.reload && data.success) {
        const current = this.props.history.location.pathname;
        this.props.history.go(current);
      } else if (data.success) {
        return this.props.history.push(
          utils.helperFunctions.common.getLoginRedirectionUrl(
            constants.USER_ROLES.APPLICATION_ADMIN
          )
        );
      } else {
        this.setState({
          hasError: true,
          errorMessage: data.message || 'Failed to authenticate',
        });
      }
    } catch (error) {
      let message = 'Failed to authenticate';
      if (error.response && error.response.data) {
        message = error.response.data.message;
      }
      this.setState({ hasError: true, errorMessage: message });
    }
    this.setState({ isLoginLoading: false });
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    reload: PropTypes.bool,
  };
  render() {
    const { handleSubmit } = this.props;
    const { hasError, errorMessage, isLoginLoading } = this.state;
    return (
      <div className="container-align-center-body">
        <Paper className="pd-10" elevation={3}>
          <div className="rakuten-sans-font login-header">
            Observability Admin Dashboard
          </div>

          {hasError && (
            <div className="alert-login">
              <AlertComponent hasIcon={true} open={hasError}>
                {errorMessage}
              </AlertComponent>
            </div>
          )}

          <form onSubmit={handleSubmit(this.onFormSubmit)}>
            <TextFieldComponent
              className="username-input"
              label="User Name"
              name="username"
            />
            <TextFieldComponent
              type="password"
              label="Password"
              name="pwd"
              custom={{ autoComplete: 'off' }}
            />
            <div className="margin-1rem">
              <ButtonGroup
                orientation="vertical"
                color="primary"
                aria-label="vertical contained primary button group"
                variant="contained"
                fullWidth={true}
              >
                <Button type="submit" disabled={isLoginLoading}>
                  Login
                  {isLoginLoading && (
                    <CircularProgress color="primary" size={15} />
                  )}
                </Button>
              </ButtonGroup>
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.username) {
    errors.username = 'Required';
  }
  if (!values.pwd) {
    errors.pwd = 'Required';
  }

  return errors;
};

export default reduxForm({
  form: 'loginForm', // a unique identifier for this form
  validate,
})(Login);
