import React, { Component, Fragment } from 'react';
import { Card } from '@material-ui/core';
import { getAuditLogsAction } from '../../actions/auditlogs';
import utils from '../../utils/utils';
import { toggleLoaderAction } from '../../actions/common';
import PageHeader from '../common/PageHeader';
import PropTypes from 'prop-types';
import Table from '../common/Table';
import { reduxForm } from 'redux-form';
import SearchField from '../common/SearchField';
import { SelectFieldComponent } from '../common/formComponents';

const {
  system: {
    constants: { AUDIT_LOGS, PlACEHOLDERS },
  },
  helperFunctions: { common },
} = utils;

const filterOptions = [
  { value: AUDIT_LOGS.FILTER.ADMIN_EMAIL, name: 'Email Id' },
  { value: AUDIT_LOGS.FILTER.ACTION, name: 'Action' },
  { value: AUDIT_LOGS.FILTER.ENTITY, name: 'Entity' },
];

const actionOptions = [
  { value: AUDIT_LOGS.ACTIONS.ADD, name: 'Add' },
  { value: AUDIT_LOGS.ACTIONS.UPDATE, name: 'Update' },
  { value: AUDIT_LOGS.ACTIONS.DELETE, name: 'Delete' },
];

const entityOptions = [
  { value: AUDIT_LOGS.RESOURCE_TYPES.BILLING_ACCOUNT, name: 'Billing Account' },
  { value: AUDIT_LOGS.RESOURCE_TYPES.TEAM, name: 'Team' },
  { value: AUDIT_LOGS.RESOURCE_TYPES.USER, name: 'User' },
];

export class Auditlogs extends Component {
  state = {
    logs: [],
    pagination: { page: 1, total: 0 },
    currentPage: 1,
    hasError: false,
    errorDisplay: '',
    loading: false,
    currentFilter: '',
    filterValue: '',
  };

  static propTypes = {
    dispatch: PropTypes.func,
    deleteAction: PropTypes.func.isRequired,
    searchResult: PropTypes.func.isRequired,
    searchList: PropTypes.string.isRequired,
  };

  checkIfQueryRequired = (query) => {
    if (query.pageNo) {
      return true;
    }

    if (query.adminEmailId) {
      return true;
    }

    if (query.action) {
      return true;
    }

    if (query.target) {
      return true;
    }

    return false;
  };
  handleAuditLogs = (data) => {
    if (data.success && data.auditLogsList && data.auditLogsList.length) {
      this.setState({
        logs: data.auditLogsList,
        currentPage: Number(data.pagination.pageNo),
        pagination: data.pagination,
      });
    } else {
      this.setState({
        logs: [],
        currentPage: Number(data.pagination.pageNo),
        pagination: data.pagination,
      });
    }
  };
  getAuditLogs = async (query) => {
    try {
      toggleLoaderAction();
      const { data } = await getAuditLogsAction(query);
      this.handleAuditLogs(data);

      toggleLoaderAction();
    } catch (error) {
      toggleLoaderAction();
    }
  };

  getRowId = (row) => {
    return `${row.auditId}`;
  };

  onPageChange = (nextPage) => {
    const parsedQs = common.getParsedQuery(this.props.history.location.search);
    parsedQs.pageNo = nextPage;
    const url = `?${common.getStringifiedQuery(parsedQs)}`;
    this.props.history.push(url);
  };

  onFilterChange = (event) => {
    event.preventDefault();
    this.setState({ currentFilter: event.target.value, filterValue: '' });
  };

  onFilterFieldsChange = (event) => {
    const { currentFilter } = this.state;
    this.setState({
      filterValue:
        currentFilter === AUDIT_LOGS.FILTER.ADMIN_EMAIL
          ? event.target[0].value
          : event.target.value,
    });

    let parsedQs = common.getParsedQuery(this.props.history.location.search);
    parsedQs = { pageNo: parsedQs.pageNo };
    parsedQs[currentFilter] =
      currentFilter === AUDIT_LOGS.FILTER.ADMIN_EMAIL
        ? event.target[0].value
        : event.target.value;
    parsedQs.pageNo = 1;
    const url = `?${common.getStringifiedQuery(parsedQs)}`;
    this.props.history.push(url);
  };

  isQueryUpdated = (prevParsedQs, currentParsedQs) => {
    if (prevParsedQs.pageNo !== currentParsedQs.pageNo) {
      return true;
    }

    if (prevParsedQs.adminEmailId !== currentParsedQs.adminEmailId) {
      return true;
    }

    if (prevParsedQs.action !== currentParsedQs.action) {
      return true;
    }

    if (prevParsedQs.target !== currentParsedQs.target) {
      return true;
    }

    return false;
  };

  closeAlert = () => this.setState({ hasError: false });

  componentDidMount = () => {
    const parsedQs = common.getParsedQuery(this.props.location.search);
    if (this.checkIfQueryRequired(parsedQs)) {
      this.setState({
        currentFilter:
          'adminEmailId' in parsedQs
            ? 'adminEmailId'
            : 'action' in parsedQs
            ? 'action'
            : 'target' in parsedQs
            ? 'target'
            : '',
        filterValue:
          parsedQs.adminEmailId || parsedQs.action || parsedQs.target,
      });
      this.getAuditLogs(parsedQs);
    } else {
      this.getAuditLogs();
    }
  };

  componentDidUpdate = (prevProps) => {
    const prevParsedQs = common.getParsedQuery(prevProps.location.search);
    const currentParsedQs = common.getParsedQuery(this.props.location.search);

    if (this.isQueryUpdated(prevParsedQs, currentParsedQs)) {
      this.getAuditLogs(currentParsedQs);
    }
  };

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  render() {
    const { logs, currentPage, pagination } = this.state;

    const columns = [
      { id: 'adminEmailId', label: 'Admin`s Email' },
      { id: 'action', label: 'Action' },
      { id: 'target', label: 'Entity Type' },
      {
        id: 'targetInfo',
        label: 'Entity Info',
        format: (r) =>
          r.targetInfo && r.targetInfo.meta ? r.targetInfo.meta : '--',
      },
      {
        id: 'createdAt',
        label: 'Time',
        format: (r) => common.getFormattedDateInDays(r.createdAt),
      },
    ];

    const action = (
      <div className="display-flex">
        <div className="min-width-12rem">
          <SelectFieldComponent
            label="Filter By"
            name="filterBy"
            placeholder="Filter By"
            className="width-90"
            options={filterOptions}
            enableFilterCss={true}
            formControlSize="small"
            customOnChange={this.onFilterChange}
            isControlledSelect={true}
            selectedValue={this.state.currentFilter}
          />
        </div>
        <div>
          {(() => {
            const { currentFilter, filterValue } = this.state;
            switch (currentFilter) {
              case AUDIT_LOGS.FILTER.ENTITY:
                return (
                  <div className="min-width-12rem">
                    <SelectFieldComponent
                      label="Entity"
                      name="entity"
                      placeholder="Entity"
                      className="width-90"
                      options={entityOptions}
                      enableFilterCss={true}
                      formControlSize="small"
                      customOnChange={this.onFilterFieldsChange}
                      isControlledSelect={true}
                      selectedValue={filterValue}
                    />
                  </div>
                );
              case AUDIT_LOGS.FILTER.ACTION:
                return (
                  <div className="min-width-12rem">
                    <SelectFieldComponent
                      label="Action"
                      name="action"
                      placeholder="Action"
                      className="width-90"
                      options={actionOptions}
                      enableFilterCss={true}
                      formControlSize="small"
                      customOnChange={this.onFilterFieldsChange}
                      isControlledSelect={true}
                      selectedValue={filterValue}
                    />
                  </div>
                );
              default:
                return (
                  <div className="mr-l-1rem">
                    <SearchField
                      placeholder={PlACEHOLDERS.EMAIL_ID}
                      onSearch={this.onFilterFieldsChange}
                    />
                  </div>
                );
            }
          })()}
        </div>
      </div>
    );

    return (
      <Fragment>
        <PageHeader title="Auditlogs" actions={action} />
        <Card className="pd-10 mr-1rem">
          <Table
            rows={logs}
            columns={columns}
            rowIdKey={this.getRowId}
            page={currentPage}
            onPageChange={this.onPageChange}
            noRecordsMessage={<span>No Audit logs!!</span>}
            total={pagination.total}
          />
        </Card>
      </Fragment>
    );
  }
}

export default reduxForm({
  form: 'auditLogs',
})(Auditlogs);
