import http from './requestor';
import {
  DELETE_USER_SUCCESS,
  DELETE_USER_STARTED,
  DELETE_USER_FAILED,
  DELETE_USER_COMPLETED,
  USER_SEARCH_RESULT,
  USER_SEARCH_STARTED,
  USER_SEARCH_FAILED,
  USER_SEARCH_COMPLETED,
} from '../common/actionTypes';

const baseUrl = 'api/v1/users';

const getUrl = (endpoint) => `${baseUrl}${endpoint}`;

export const getUsersAction = (query) => http.get(getUrl('/'), query);
/**
 * This reducers are used with middleware !
 * @param {*} payload
 */
export const deleteUserStart = (payload) => {
  return {
    type: DELETE_USER_STARTED,
    payload,
  };
};
export const deleteUserSucess = (payload) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload,
  };
};
export const deleteUserFailure = (payload) => {
  return {
    type: DELETE_USER_FAILED,
    payload,
  };
};
export const deleteUserCompleted = (payload) => {
  return {
    type: DELETE_USER_COMPLETED,
    payload,
  };
};

export const startSearch = (payload) => {
  return {
    type: USER_SEARCH_STARTED,
    payload,
  };
};

export const searchResult = (payload) => {
  return {
    type: USER_SEARCH_RESULT,
    payload,
  };
};

export const searchFailed = (payload) => {
  return {
    type: USER_SEARCH_FAILED,
    payload,
  };
};
export const searchCompleted = (payload) => {
  return {
    type: USER_SEARCH_COMPLETED,
    payload,
  };
};
