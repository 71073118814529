import constants from './constants';

const config = {
  GATEWAY_BASE_URL:
    process.env.REACT_APP_GATEWAY_BASE_URL || 'http://localhost:3000',
  DISABLE_SOFT_DELETE_USER:
    process.env.REACT_APP_DISABLE_SOFT_DELETE_USER || false,
  LOGIN_REDIRECTION_URL: {
    [constants.USER_ROLES.APPLICATION_ADMIN]: '/admin/billing_accounts',
  },
  PAGINATION: {
    DEFAULT_LIMIT: 8,
  },
  DEBOUNCE_TIME: 500,
};

export default config;
