import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { loggedInUser } from './auth';
import { mobileDrawerState, toggleLoader, showLogin } from './common';
import { userReducer } from './user';
const appReducer = combineReducers({
  auth: loggedInUser,
  form: formReducer,
  isSessionExpr: showLogin,
  isMobileDrawerOpen: mobileDrawerState,
  enableLoader: toggleLoader,
  user: userReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
