import React, { Component, Fragment } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ProgressLoader } from './common/Loaders';
import { connect } from 'react-redux';

import utils from '../utils/utils';
import routes from './routes';
import PropTypes from 'prop-types';
import WrappedComponents from './WrappedComponents';

import InjectLoggedInUser from './Middlewares/InjectLoggedInUser';
import InjectLoaderStatus from './Middlewares/InjectLoaderStatus';
import SessionExpire from './system/SessionExpire';

const stylesCallback = (theme) => {
  return {
    toolbar: theme.mixins.toolbar,
  };
};

class App extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    isLoggedIn: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object,
    user: PropTypes.object,
    isSessionExpr: PropTypes.bool.isRequired,
    enableLoader: PropTypes.bool,
  };

  componentDidMount() {}
  render() {
    const { isLoggedIn, classes, enableLoader, isSessionExpr, user } =
      this.props;
    return (
      <Fragment>
        <div className={isLoggedIn ? '' : 'container-open-body'}>
          <div className={isLoggedIn ? '' : 'header-wrap'}>
            <ProgressLoader isLoading={enableLoader && isLoggedIn} />
            <WrappedComponents.Header />

            <Route
              exact
              path="/"
              render={() => {
                return isLoggedIn ? (
                  <Redirect
                    to={utils.helperFunctions.common.getLoginRedirectionUrl(
                      user.userRole
                    )}
                  />
                ) : (
                  <Redirect to="/login" />
                );
              }}
            />
            <Route
              exact
              path="/login"
              render={(props) => {
                return isLoggedIn ? (
                  <Redirect
                    to={utils.helperFunctions.common.getLoginRedirectionUrl(
                      user.userRole
                    )}
                  />
                ) : (
                  <WrappedComponents.Login {...props} />
                );
              }}
            />
          </div>
          {isSessionExpr && (
            <div>
              <SessionExpire></SessionExpire>
            </div>
          )}
          <Switch>
            <Fragment>
              {routes.open.map((curr) => (
                <Route
                  key={curr.path}
                  exact={curr.exact}
                  path={curr.path}
                  component={curr.component}
                />
              ))}
              {isLoggedIn && (
                <Fragment>
                  <div className={classes.toolbar} />
                  <Grid container spacing={3}>
                    <Grid item xs={false} lg={2}>
                      <WrappedComponents.NavBar />
                    </Grid>
                    <Grid item md={12} xs={12} sm={12} lg={10}>
                      {routes.protected.map((curr) => (
                        <Route
                          key={curr.path}
                          exact={curr.exact}
                          path={curr.path}
                          component={curr.component}
                        />
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      <Paper>
                        <WrappedComponents.Footer />
                      </Paper>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Fragment>
            <Route component={WrappedComponents.NotFoundComponent} />
          </Switch>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isSessionExpr: state.isSessionExpr,
});

const AppContainer = withStyles(stylesCallback)(
  InjectLoaderStatus(
    InjectLoggedInUser(connect(mapStateToProps)(withRouter(App)))
  )
);
export default AppContainer;
