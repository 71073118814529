import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { MenuList, MenuItem } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import { dispatchMobileDrawerState } from '../../actions/common';
import utils from '../../utils/utils';

const drawerWidth = '240';
const stylesCallback = (theme) => {
  return {
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth + 'px',
    },
    toolbar: theme.mixins.toolbar,
    selected: {
      backgroundColor: 'rgba(247, 168, 49, 0.8) !important',
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      color: 'black',
    },
  };
};

const applicationAdminNavLinks = [
  { label: 'Billing Accounts', link: '/admin/billing_accounts' },
  { label: 'Teams', link: '/admin/teams' },
  { label: 'Users', link: '/admin/users' },
  { label: 'Audit Logs', link: '/admin/logs' },
];

export class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      mobileOpen: false,
      activeLink: '',
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.getDrawer = this.getDrawer.bind(this);
  }

  handleDrawerToggle() {
    this.props.dispatch(dispatchMobileDrawerState());
  }

  applicationAdminMenu() {
    const {
      location: { pathname },
      classes,
    } = this.props;
    return (
      <MenuList>
        {applicationAdminNavLinks.map((navLink) => (
          <MenuItem
            selected={pathname === navLink.link}
            component={Link}
            to={navLink.link}
            classes={{ selected: classes.selected }}
            key={navLink.link}
          >
            {pathname === navLink.link ? <b>{navLink.label}</b> : navLink.label}
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  getDrawer(user) {
    if (utils.helperFunctions.common.checkIsApplicationAdmin(user.userRole)) {
      return this.applicationAdminMenu();
    }
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isMobileDrawerOpen: PropTypes.bool.isRequired,
    classes: PropTypes.object,
    location: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };
  render() {
    const { classes, user, isLoggedIn, isMobileDrawerOpen } = this.props;

    if (!isLoggedIn) {
      return null;
    }

    return (
      <Fragment>
        <CssBaseline />
        {isMobileDrawerOpen && (
          <Drawer
            // container='div'
            // variant="temporary"
            open={isMobileDrawerOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {this.getDrawer(user)}
          </Drawer>
        )}
        <Hidden mdDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={`${classes.toolbar}`} />
            {this.getDrawer(user)}
          </Drawer>
        </Hidden>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isMobileDrawerOpen: state.isMobileDrawerOpen,
});

export default withStyles(stylesCallback)(
  withRouter(connect(mapStateToProps)(NavBar))
);
