import http from '../actions/requestor';

const baseUrl = 'api/v1/users';

export const deleteUser = ({ emailId }) => {
  return http.delete(`${baseUrl}`, { data: { emailId } });
};

export const searchUser = ({ searchBy = 'emailId', keyword }) => {
  return http.get(`${baseUrl}/`, { [searchBy]: keyword });
};
