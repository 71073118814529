import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

export default function SearchFilter(props) {
  const chipLabelMap = {
    emailId: 'User',
    billingName: 'Billing Account',
    teamName: 'Team',
  };
  const allowedStates = [
    { id: 'user', value: 'emailId', label: 'User' },
    { id: 'billing_account', value: 'billingName', label: 'Billing Account' },
    { id: 'team', value: 'teamName', label: 'Team' },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentValue, setCurrentValue] = React.useState(null);
  const [menuVisible, setMenuVisible] = React.useState(false);
  const [chipValue, setChipValue] = React.useState('User');
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuVisible(!menuVisible);
  };

  const onClick = (value) => {
    value === null ? setChipValue('User') : setChipValue(chipLabelMap[value]);
    props.onSelect(value);
    setCurrentValue(value);
    setMenuVisible(false);
  };

  return (
    <div className="display-flex margin-left-right-1rem">
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Filter by:
      </Button>
      <div className="display-flex">
        {' '}
        <Chip label={chipValue} />{' '}
      </div>
      <Menu
        id="simple-menu"
        keepMounted
        open={menuVisible}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
      >
        {allowedStates.map((obj) => (
          <MenuItem key={obj.value}>
            <Checkbox
              onClick={(ev) => {
                onClick(ev.target.checked ? obj.value : null);
              }}
              key={obj.value}
              color="primary"
              checked={obj.value === currentValue}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            {obj.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

SearchFilter.propTypes = {
  onSelect: PropTypes.func,
};
