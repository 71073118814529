import AuthenticateUser from './Middlewares/AuthenticateUser';
import InjectLoggedInUser from './Middlewares/InjectLoggedInUser';

import Login from './system/Login';
import NotFoundComponent from './system/NotFoundComponent';
import { Header, Footer, NavBar } from './layout';
import AuthoriseApplicationAdmin from './Middlewares/AuthoriseApplicationAdmin';
import Users from './users/Users';
import BillingAccounts from './billingAccounts/BillingAccounts';
import Teams from './teams/Teams';
import AuditLogs from './auditLogs/auditlogs';

const WrappedComponents = {
  Login: Login,
  NotFoundComponent: NotFoundComponent,
  Header: InjectLoggedInUser(Header),
  Footer: InjectLoggedInUser(Footer),
  NavBar: InjectLoggedInUser(NavBar),
  Users: AuthoriseApplicationAdmin(AuthenticateUser(Users)),
  Teams: AuthoriseApplicationAdmin(AuthenticateUser(Teams)),
  BillingAccounts: AuthoriseApplicationAdmin(AuthenticateUser(BillingAccounts)),
  AuditLogs: AuthoriseApplicationAdmin(AuthenticateUser(AuditLogs)),
};

export default WrappedComponents;
