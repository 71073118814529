import WrappedComponents from './WrappedComponents';

const routes = {
  open: [
    { path: '/callback/:type/login', component: WrappedComponents.SsoLogin },
  ],
  protected: [
    { path: '/admin/users', exact: true, component: WrappedComponents.Users },
    { path: '/admin/teams', exact: true, component: WrappedComponents.Teams },
    {
      path: '/admin/billing_accounts',
      exact: true,
      component: WrappedComponents.BillingAccounts,
    },
    {
      path: '/admin/logs',
      exact: true,
      component: WrappedComponents.AuditLogs,
    },
  ],
};

export default routes;
