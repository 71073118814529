import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
/**
 * Created a custom middleware
 */
import { apiMiddleware } from '../middleware/api';
import rootReducer from '../reducers';

const middleware = applyMiddleware(thunk, apiMiddleware);
const reduxDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
let store;
if (reduxDevTools) {
  store = createStore(rootReducer, compose(middleware, reduxDevTools));
} else {
  store = createStore(rootReducer, compose(middleware));
}

export default store;
