import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Tooltip } from '@material-ui/core';
import system from '../../utils/system/system';
import PropTypes from 'prop-types';

const DEFAULT_PAGE_SIZE = system.config.PAGINATION.DEFAULT_LIMIT;

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
});

const checkIfColumNeedsLabel = (column) => column.type !== 'button';

const handleClick = (column, row, props, event) => {
  event.preventDefault();
  const buttonFnName = column.buttonPropsId || `on${column.id}`;
  if (props[buttonFnName] && typeof props[buttonFnName] === 'function') {
    props[column.buttonPropsId || `on${column.id}`](row);
  }
};

const handleRowClick = (row, fn, event) => {
  event.preventDefault();
  if (fn) {
    fn(row);
  }
};

const CustomTable = (props) => {
  const classes = useStyles();
  const {
    columns,
    rows,
    rowIdKey,
    onPageChange,
    page = 1,
    noRecordsMessage,
    total,
    onRowClick,
    isPaginationReq = true,
  } = props;
  let currentPage = page;
  if (page === 1 && (!rows || !rows.length)) {
    return (
      <Typography component="h5">{noRecordsMessage || 'No Records'}</Typography>
    );
  } else if (!rows || !rows.length) {
    currentPage = 0;
  }

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    onPageChange(Number(pageNumber) + 1);
  };
  return (
    <Fragment>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {checkIfColumNeedsLabel(column) ? column.label : ' '}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              let customRowItems = {};
              if (onRowClick) {
                customRowItems.onClick = handleRowClick.bind(
                  null,
                  row,
                  onRowClick
                );
              }
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={
                    typeof rowIdKey === 'string' ? row[rowIdKey] : rowIdKey(row)
                  }
                  {...customRowItems}
                >
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.type === 'button') {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Button
                            onClick={handleClick.bind(null, column, row, props)}
                            variant="contained"
                            color="secondary"
                            size="small"
                          >
                            {column.format ? column.format(row) : column.label}
                          </Button>
                        </TableCell>
                      );
                    }
                    value = column.format ? column.format(row) : value;
                    const tooltip = column.tooltipFormat
                      ? column.tooltipFormat(row)
                      : value;
                    return (
                      <TableCell align={column.align} key={column.id}>
                        {column.isToolTipNotRequired ? (
                          <span>{value}</span>
                        ) : (
                          <Tooltip title={tooltip} placement="right-start">
                            <span>{value}</span>
                          </Tooltip>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isPaginationReq && (
        <TablePagination
          rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
          component="div"
          count={total}
          rowsPerPage={DEFAULT_PAGE_SIZE}
          page={Number(currentPage) ? Number(currentPage) - 1 : currentPage}
          onChangePage={handlePageChange}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Fragment>
  );
};

CustomTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  rowIdKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  onPageChange: PropTypes.func,
  noRecordsMessage: PropTypes.object,
  page: PropTypes.number,
  total: PropTypes.number,
  onRowClick: PropTypes.func,
  isPaginationReq: PropTypes.bool,
};

export default CustomTable;
