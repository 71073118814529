import axios from 'axios';
import { logoutAction } from '../actions/auth';
import alerts from '../utils/helperFunctions/alerts';

export const interceptor = (dispatch, history) => {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async (error) => {
      if (error && error.response && error.response.status === 401) {
        await logoutAction(dispatch);
        history.push('/login');
        alerts.error(error.response.data.message || 'Session Expired');
      }
      return Promise.reject(error);
    }
  );
};
