import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { reduxForm } from 'redux-form';
import {
  TextFieldComponent,
  AlertComponent,
  SelectFieldComponent,
} from '../common/formComponents';
import Dialog from '../common/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import system from '../../utils/system/system';

const statusOptions = [
  { value: 'true', name: system.constants.USER_STATUS_DISPLAY_NAME.ACTIVE },
  { value: 'false', name: system.constants.USER_STATUS_DISPLAY_NAME.IN_ACTIVE },
];

class BillingAcctForm extends Component {
  state = {
    billingAcctDetails: null,
    type: 'new',
    values: '',
  };

  onFromSubmit = (values) => {
    const { onSubmit, onEdit, type } = this.props;
    if (type === 'old') {
      return onEdit(values);
    }
    onSubmit(values);
  };

  getBillingAcctForm = () => {
    const { handleSubmit, hasError, errorDisplay, closeAlert } = this.props;
    const { type } = this.state;
    return (
      <form onSubmit={handleSubmit(this.onFromSubmit)} id="createBillingAcct">
        {hasError && (
          <AlertComponent open={hasError} onClose={closeAlert}>
            {errorDisplay}
          </AlertComponent>
        )}
        {type !== 'old' ? (
          <>
            <TextFieldComponent
              label="First Name"
              name="firstName"
              placeholder="User display First name"
              className="width-90"
              required
            />

            <TextFieldComponent
              label="Last Name"
              name="lastName"
              placeholder="User display Last name"
              className="width-90"
              required
            />

            <TextFieldComponent
              label="Email"
              name="email"
              placeholder="Email-id will be used for login"
              className="width-90"
              required
              displayError
            />

            <TextFieldComponent
              label="Billing Entity"
              name="billingEntity"
              placeholder="Billing Entity"
              className="width-90"
              required
            />

            <TextFieldComponent
              label="Team"
              name="team"
              placeholder="Team"
              className="width-90"
              required
            />

            <TextFieldComponent
              label="Organization"
              name="org"
              placeholder="Organization"
              className="width-90"
              required
            />
          </>
        ) : (
          <SelectFieldComponent
            label="Status"
            name="isActive"
            placeholder="Status Active/In-Active"
            className="width-90"
            options={statusOptions}
            required
          />
        )}
      </form>
    );
  };

  handleUpdate = () => {
    const { billingAcctDetails, type, initialize } = this.props;
    if (type === 'old' && billingAcctDetails) {
      billingAcctDetails.isActive = billingAcctDetails.active
        ? 'true'
        : 'false';
      this.setState({ type });
      initialize(billingAcctDetails);
    }
  };

  componentDidMount = () => {
    this.handleUpdate();
  };

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    billingAcctDetails: PropTypes.object.isRequired,
    title: PropTypes.string,
    initialize: PropTypes.func,
    pristine: PropTypes.bool,
    hasError: PropTypes.bool,
    errorDisplay: PropTypes.string,
    closeAlert: PropTypes.func,
    loading: PropTypes.bool,
  };

  render() {
    const { onClose, title, pristine, type, loading } = this.props;
    const actions = [
      <Button
        key="cancel"
        type="button"
        color="secondary"
        onClick={onClose}
        size="small"
        disabled={loading}
      >
        Cancel
      </Button>,
      <Button
        key="add"
        form="createBillingAcct"
        type="submit"
        color="primary"
        size="small"
        disabled={pristine || loading}
      >
        {type === 'old' ? 'Update' : 'Add'}
        {loading && <CircularProgress color="primary" size={15} />}
      </Button>,
    ];
    const content = this.getBillingAcctForm();
    return (
      <Dialog
        open={true}
        title={
          title || type === 'old'
            ? 'Update Billing Account'
            : 'Create Billing Account'
        }
        content={content}
        actions={actions}
        onClose={onClose}
        disableBackdropClick={true}
      />
    );
  }
}

const requireFields = [
  'billingEntity',
  'email',
  'firstName',
  'lastName',
  'team',
  'org',
];

const validate = (values) => {
  const errors = {};
  const emailReg = RegExp(/^[a-zA-Z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);

  requireFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = system.messages.FIELD_REQ;
    }
    if (values.email && !emailReg.test(values.email)) {
      errors.email = system.messages.INVALID_EMAIL;
    }
  });

  return errors;
};

export default reduxForm({
  form: 'billingAcctFrom', // a unique identifier for this form
  validate,
})(BillingAcctForm);
