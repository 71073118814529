import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import utils from '../../utils/utils';

export default function (ComposedComponent) {
  class AuthoriseApplicationAdmin extends React.Component {
    validateUser() {
      const { isLoggedIn, userRole, history, location } = this.props;
      if (
        !isLoggedIn ||
        !utils.helperFunctions.common.checkIsApplicationAdmin(userRole)
      ) {
        history.push('/', { urlRequested: location.pathname });
      }
    }

    componentDidMount() {
      this.validateUser();
    }

    componentDidUpdate() {
      this.validateUser();
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  AuthoriseApplicationAdmin.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    userRole: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  function mapStateToProps(state) {
    return {
      isLoggedIn: state.auth.isLoggedIn,
      userRole: state.auth.userRole,
    };
  }

  return connect(mapStateToProps)(withRouter(AuthoriseApplicationAdmin));
}
