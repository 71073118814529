export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_MOBILE_DRAWER_STATE = 'SET_MOBILE_DRAWER_STATE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const TOGGLE_LOADER_STATE = 'TOGGLE_LOADER_STATE';
export const SESSION_EXPIRE = 'SESSION_EXPIRE';
export const DELETE_USER_STARTED = 'DELETE_USER_STARTED';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
export const DELETE_USER_COMPLETED = 'DELETE_USER_COMPLETED';
export const USER_SEARCH_STARTED = 'USER_SEARCH_STARTED';
export const USER_SEARCH_RESULT = 'USER_SEARCH_RESULT';
export const USER_SEARCH_FAILED = 'USER_SEARCH_FAILED';
export const USER_SEARCH_COMPLETED = 'USER_SEARCH_COMPLETED';
