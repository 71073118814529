import {
  DELETE_USER_STARTED,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_USER_COMPLETED,
  USER_SEARCH_RESULT,
  USER_SEARCH_COMPLETED,
} from '../common/actionTypes';

const initState = {
  deleteAction: DELETE_USER_COMPLETED,
  searchResult: { searchCompleted: false },
};

/**
 * New reducers for middlewares
 * @param {*} state
 * @param {*} action
 */
export function userReducer(state = initState, action = {}) {
  switch (action.type) {
    case DELETE_USER_STARTED:
      return {
        ...state,
        deleteAction: 'DELETE_USER_STARTED',
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteAction: 'DELETE_USER_SUCCESS',
      };
    case DELETE_USER_FAILED:
      return {
        ...state,
        deleteAction: 'DELETE_USER_FAILED',
      };
    case DELETE_USER_COMPLETED:
      return {
        ...state,
        deleteAction: 'DELETE_USER_COMPLETED',
      };
    case USER_SEARCH_RESULT:
      return {
        ...state,
        searchResult: {
          ...action.payload,
          searchCompleted: true,
        },
      };
    case USER_SEARCH_COMPLETED:
      return {
        ...state,
        searchResult: {
          searchCompleted: false,
        },
      };

    default:
      return state;
  }
}
