import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { dispatchMobileDrawerState } from '../../actions/common';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutAction } from '../../actions/auth';
import ChangePassword from '../changePassword/ChangePassword';
import { useHistory } from 'react-router-dom';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      marginLeft: drawerWidth,
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'white !important',
    },
    backgroundColor: 'white !important',
    padding: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
}));

const handleDrawerToggle = (dispatch) => {
  dispatch(dispatchMobileDrawerState(''));
};

const handleLogout = async (history, dispatch, handleClose) => {
  handleClose(null);
  await logoutAction(dispatch);
  history.push('/');
};

const Header = (props) => {
  const { isLoggedIn } = props;
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const open = Boolean(anchorEl, openDialog);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenDialog(null);
  };

  const onChangePassword = () => {
    handleClose();
    setOpenDialog(!openDialog);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  if (!isLoggedIn) {
    return (
      <header>
        <div className="rakuten-sans-font sixthsense-logo">
          Rakuten SixthSense
        </div>
      </header>
    );
  }

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle.bind(null, props.dispatch)}
          className={classes.menuButton}
        >
          <MenuIcon color="primary" />
        </IconButton>
        <div className={classes.title}>
          <div className="rakuten-sans-font toolbar-logo">
            <b>Observability Admin Dashboard</b>
          </div>
        </div>
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle color="primary" />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={onChangePassword}>Change Password</MenuItem>
            {openDialog && <ChangePassword closeDialog={closeDialog} />}
            <MenuItem
              onClick={handleLogout.bind(
                null,
                history,
                props.dispatch,
                handleClose
              )}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  userOrigin: PropTypes.string,
  name: PropTypes.string,
};

export default connect(null, { dispatchMobileDrawerState })(Header);
