import React, { Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import { Typography, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

const Tools = (props) => {
  const { onDelete, onEdit, row, requiredTools, entity } = props;

  const deleteRow = () => {
    onDelete(row);
  };

  const editRow = () => {
    onEdit(row);
  };

  return (
    <Fragment>
      <div className="display-flex">
        {requiredTools.enableEdit && (
          <Tooltip
            title={
              <Fragment>
                <Typography variant="caption">
                  Click to Update {entity}
                </Typography>
              </Fragment>
            }
            placement="right-start"
            arrow
          >
            <IconButton aria-label="update" onClick={editRow}>
              <CreateIcon />
            </IconButton>
          </Tooltip>
        )}

        {requiredTools.enableDelete && (
          <Tooltip
            title={
              <Fragment>
                <Typography variant="caption">
                  Click to Delete {entity}
                </Typography>
              </Fragment>
            }
            placement="right-start"
            arrow
          >
            <IconButton aria-label="delete" onClick={deleteRow}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Fragment>
  );
};

Tools.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  row: PropTypes.object.isRequired,
  requiredTools: PropTypes.object.isRequired,
  entity: PropTypes.string.isRequired,
};

export default Tools;
