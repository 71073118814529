import system from '../system/system';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';

import { SET_CURRENT_USER } from '../../common/actionTypes';
import constants from '../system/constants';

const hasToken = () =>
  !!localStorage.getItem(system.constants.TOKEN_STORAGE_NAME);

const getToken = (key) => localStorage.getItem(key);

const saveToSession = (key, token) => localStorage.setItem(key, token);

const endSession = () => {
  localStorage.removeItem(system.constants.TOKEN_STORAGE_NAME);
};

const decodeToken = (token) => (token ? jwtDecode(token) : void 0);

const getLoginRedirectionUrl = (userRole) => {
  return system.config.LOGIN_REDIRECTION_URL[userRole]
    ? system.config.LOGIN_REDIRECTION_URL[userRole]
    : system.config.LOGIN_REDIRECTION_URL['DEFAULT'];
};

const dispatchTokenToStore = (dispatch) => {
  const accessToken = system.constants.TOKEN_STORAGE_NAME;
  const token = getToken(accessToken);
  if (token) {
    dispatch({
      type: SET_CURRENT_USER,
      payload: { userRole: constants.USER_ROLES.APPLICATION_ADMIN },
    });
  }
};

const setAuthHeaders = () => {
  const accessToken = system.constants.TOKEN_STORAGE_NAME;
  const token = getToken(accessToken);
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Basic ${token}`;
  }
};

const checkIsApplicationAdmin = (role) =>
  role && role === system.constants.USER_ROLES.APPLICATION_ADMIN;
const checkIsAccountAdmin = (role) =>
  role && role === system.constants.USER_ROLES.ACCOUNT_ADMIN;

const handleLoggedInUser = (dispatch) => {
  setAuthHeaders();
  dispatchTokenToStore(dispatch);
};

const timeDiffFromCurrentTime = (toDate, range) =>
  moment().diff(moment(toDate), range);

const getFormattedDateInDays = (dateString, formatedString = true) => {
  let key = 'Days';
  let value = timeDiffFromCurrentTime(dateString, 'days');
  if (value < 1) {
    value = timeDiffFromCurrentTime(dateString, 'hours');
    key = 'Hours';
    if (value < 1) {
      value = timeDiffFromCurrentTime(dateString, 'minutes');
      key = 'Minutes';
    }
  } else if (value > 7) {
    value = moment().diff(moment(dateString), 'weeks');
    key = 'Weeks';
    if (value > 4) {
      value = moment().diff(moment(dateString), 'months');
      key = 'Months';
      if (value > 12) {
        value = moment().diff(moment(dateString), 'years');
        key = 'Years';
      }    
    }
  }
  if (formatedString) {
    return `${value} ${key} ago`;
  }
  return { key, value };
};

const formatToDisplayDate = (date) => {
  if (new Date(date) !== 'Invalid Date') {
    return new Date(date).toISOString();
  }
  return date;
};

const getUserDisplayStatus = (status) =>
  system.constants.USER_STATUS_DISPLAY_NAME[status] || status;

const getStringifiedQuery = (object) => queryString.stringify(object);

const getParsedQuery = (string) => queryString.parse(string);

const stringToBoolean = (str) => {
  try {
    const parsedBool = JSON.parse(str.toLowerCase());
    return parsedBool;
  } catch (error) {
    return false;
  }
};

const getResponseFromError = (error) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    return error.response.data.message;
  }
  return null;
};

const common = {
  getToken,
  hasToken,
  decodeToken,
  saveToSession,
  getLoginRedirectionUrl,
  dispatchTokenToStore,
  endSession,
  checkIsApplicationAdmin,
  checkIsAccountAdmin,
  handleLoggedInUser,
  getFormattedDateInDays,
  getUserDisplayStatus,
  getStringifiedQuery,
  getParsedQuery,
  formatToDisplayDate,
  setAuthHeaders,
  stringToBoolean,
  getResponseFromError,
};

export default common;
