import http from './requestor';
import { USER_LOGOUT } from '../common/actionTypes';
import utils from '../utils/utils';
import contants from '../utils/system/constants';

const baseUrl = 'api/v1/admin/login';

const getUrl = (endpoint) => `${baseUrl}${endpoint}`;

const dispatchUserLogoutToStore = (data) => ({
  type: USER_LOGOUT,
  payload: data,
});

const handleResponse = (response, dispatch) => {
  if (response && response.data && response.data.accessToken) {
    utils.helperFunctions.common.saveToSession(
      contants.TOKEN_STORAGE_NAME,
      response.data.accessToken
    );
    utils.helperFunctions.common.handleLoggedInUser(dispatch);
  }
};

export const loginAction = async (data, dispatch) => {
  const response = await http.post(getUrl('/'), data);
  handleResponse(response, dispatch);
  return response;
};

export const logoutAction = async (dispatch) => {
  utils.helperFunctions.common.endSession();
  dispatch(dispatchUserLogoutToStore());
};
