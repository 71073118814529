import axios from 'axios';
import utils from '../utils/utils';

const getBaseUrl = (endpoint) => {
  return `${utils.system.config.GATEWAY_BASE_URL}/${endpoint}`;
};

const getUrlWithQuery = (url, queryObject) => {
  return `${url}${
    queryObject
      ? `?${utils.helperFunctions.common.getStringifiedQuery(queryObject)}`
      : ''
  }`;
};

const get = (endpoint, query, config = {}) => {
  return axios.get(getUrlWithQuery(getBaseUrl(endpoint), query), config);
};

const post = (endpoint, data, config = {}) => {
  return axios.post(getBaseUrl(endpoint), data, config);
};

const put = (endpoint, data, config = {}) => {
  return axios.put(getBaseUrl(endpoint), data, config);
};

const deleteMethod = (endpoint, config = {}) => {
  return axios.delete(getBaseUrl(endpoint), config);
};

const requestor = {
  get,
  post,
  put,
  delete: deleteMethod,
};

export default requestor;
