import http from './requestor';

const baseUrl = 'api/v1/admin';

const getUrl = (endpoint) => `${baseUrl}${endpoint}`;

export const adminUpdateUsersAction = (query) =>
  http.put(getUrl('/users'), query);

export const changePasswordAction = (data) =>
  http.put(getUrl('/password'), data);
