import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import Login from './Login';
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function SessionExpire() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={true}>
        <Login reload={true} history={history} />
      </Backdrop>
    </div>
  );
}

export default withRouter(SessionExpire);
